import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Navbar";
import EmailLogo from "../../assets/EmailLogo.svg";
import styles from "./PasswordResetPage.module.css";

const PasswordResetPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handlePasswordResetClick = async () => {
    if (!email) {
      setErrorMessage("Please enter your email.");
      return;
    }

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const body = { email };
    const headers = { "Content-Type": "application/json" };

    try {
      setIsLoading(true);
      await axios.post(`${backendUrl}/auth/reset-password`, body, { headers });
      setSuccessMessage(
        "Password reset email sent.<br />Check your inbox or spam folder."
      );
      setErrorMessage("");
      setEmailSent(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(
          error.response.data.message ||
            "An error occurred during password reset."
        );
        setSuccessMessage("");
      } else {
        setErrorMessage("An unexpected error occurred.");
        setSuccessMessage("");
      }
      setEmailSent(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInClick = () => {
    navigate("/signin");
  };

  return (
    <div className={styles.passwordResetPage}>
      <Navbar />
      <div className={styles.mainContent}>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Reset Password</h1>
          <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
            <div className={styles.inputGroup}>
              <img src={EmailLogo} alt="User" className={styles.userIcon} />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {errorMessage && (
              <div className={styles.errorText}>{errorMessage}</div>
            )}
            {successMessage && (
              <div
                className={styles.successText}
                dangerouslySetInnerHTML={{ __html: successMessage }}
              ></div>
            )}
            {!emailSent && (
              <button
                type="button"
                className={styles.resetButton}
                onClick={handlePasswordResetClick}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Reset Password"}
              </button>
            )}
          </form>
          <div className={styles.signInLinkContainer}>
            <button
              type="button"
              className={styles.linkButton}
              onClick={handleSignInClick}
            >
              Back to Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetPage;
