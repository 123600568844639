import React, { useRef, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import AlwaysAvailableIcon from "../../assets/AlwaysAvailableIcon_white.svg";
import AIIcon from "../../assets/AIIcon_white.svg";
import GrowthIcon from "../../assets/GrowthIcon_white.svg";
import styles from "./AboutSection.module.css";

const AboutSection = () => {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const lineControls = useAnimation();
  const item1Ref = useRef<HTMLDivElement>(null);
  const item2Ref = useRef<HTMLDivElement>(null);
  const item3Ref = useRef<HTMLDivElement>(null);

  const [isItem1Visible, setItem1Visible] = useState(false);
  const [isItem2Visible, setItem2Visible] = useState(false);
  const [isItem3Visible, setItem3Visible] = useState(false);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const aboutSectionElement = document.querySelector(
            `.${styles.aboutSection}`
          );
          if (aboutSectionElement) {
            const aboutSectionHeight = aboutSectionElement.clientHeight;
            const scrollY = window.scrollY;
            const scrollProgress = Math.min(
              (scrollY / aboutSectionHeight) * 1.5,
              0.8
            );
            lineControls.start({ height: `${scrollProgress * 100}%` });
          }
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lineControls]);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === item1Ref.current && !isItem1Visible) {
            controls1.start("visible");
            setItem1Visible(true); // Mark item as visible
          } else if (entry.target === item2Ref.current && !isItem2Visible) {
            controls2.start("visible");
            setItem2Visible(true); // Mark item as visible
          } else if (entry.target === item3Ref.current && !isItem3Visible) {
            controls3.start("visible");
            setItem3Visible(true); // Mark item as visible
          }
        }
      });
    };

    const observerOptions = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const currentItem1 = item1Ref.current;
    const currentItem2 = item2Ref.current;
    const currentItem3 = item3Ref.current;

    if (currentItem1) observer.observe(currentItem1);
    if (currentItem2) observer.observe(currentItem2);
    if (currentItem3) observer.observe(currentItem3);

    return () => {
      if (currentItem1) observer.unobserve(currentItem1);
      if (currentItem2) observer.unobserve(currentItem2);
      if (currentItem3) observer.unobserve(currentItem3);
    };
  }, [controls1, controls2, controls3, isItem1Visible, isItem2Visible, isItem3Visible]);

  const itemVariants = {
    hidden: { opacity: 0, y: 100, scale: 0.8, rotate: -10 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      rotate: 0,
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className={styles.landingContainer}>
      <motion.div
        className={styles.lineLeft}
        initial={{ height: 0 }}
        animate={lineControls}
      ></motion.div>
      <div className={styles.aboutSection}>
        <h1>Why PontoAI?</h1>
        <motion.div
          ref={item1Ref}
          className={styles.item}
          initial="hidden"
          animate={controls1}
          variants={itemVariants}
        >
          <img
            src={AlwaysAvailableIcon}
            alt="Always Available Support"
            className={`${styles.icon} ${styles.iconLeft}`}
          />
          <div className={styles.textRight}>
            <h3>Always Available Support</h3>
            <p>
              24/7 customer support on your website
              <br />
              ensures no customer query goes unanswered,
              <br />
              and captures leads at any hour.
            </p>
          </div>
        </motion.div>
        <motion.div
          ref={item2Ref}
          className={styles.item}
          initial="hidden"
          animate={controls2}
          variants={itemVariants}
        >
          <div className={styles.textLeft}>
            <h3>Intelligent Sales Assistance</h3>
            <p>
              Transform website visitors into buyers with <br />
              AI-driven conversations that boost <br />
              conversion rates and increase revenue.
            </p>
          </div>
          <img
            src={AIIcon}
            alt="Intelligent Sales Assistance"
            className={`${styles.icon} ${styles.iconRight}`}
          />
        </motion.div>
        <motion.div
          ref={item3Ref}
          className={styles.item}
          initial="hidden"
          animate={controls3}
          variants={itemVariants}
        >
          <img
            src={GrowthIcon}
            alt="Scalable Customer Engagement"
            className={`${styles.icon} ${styles.iconLeft}`}
          />
          <div className={styles.textRight}>
            <h3>Scalable Customer Engagement</h3>
            <p>
              Efficiently manage increasing volumes <br />
              of interactions at a fraction of the cost <br />
              as your business grows.
            </p>
          </div>
        </motion.div>
      </div>
      <motion.div
        className={styles.lineRight}
        initial={{ height: 0 }}
        animate={lineControls}
      ></motion.div>
    </div>
  );
};

export default AboutSection;
