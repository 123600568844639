import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";
import styles from "./ChatsBar.module.css";

interface Message {
  _id: string;
  message: string;
  timestamp: number;
}

interface Conversation {
  _id: string;
  company: string;
  messages: Message[];
  endTimestamp: number;
}

interface ChatsBarProps {
  onLatestConversationId: (id: string) => void;
}

const ChatsBar: React.FC<ChatsBarProps> = ({ onLatestConversationId }) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [width, setWidth] = useState(250);
  const [searchQuery, setSearchQuery] = useState("");
  const [isChatsBarVisible, setIsChatsBarVisible] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isResizing = useRef(false);
  const initialMouseX = useRef(0);
  const initialWidth = useRef(250);
  const selectedConversationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchConversations = async () => {
      const email = localStorage.getItem("userEmail");
      if (!email) {
        setError("User email not found in local storage.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/convo/conversations/user/${email}`,
          { cancelToken: source.token }
        );
        setConversations(response.data);
        if (response.data.length > 0) {
          onLatestConversationId(response.data[0]._id);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (axios.isCancel(err)) {
            console.log("Request canceled:", (err as AxiosError).message);
          } else {
            setError(
              `Failed to fetch conversations: ${(err as AxiosError).message}`
            );
          }
        } else {
          setError("Failed to fetch conversations: An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();

    return () => {
      source.cancel("Component unmounted, request canceled.");
      setLoading(false); // Reset loading state
    };
  }, [onLatestConversationId]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isResizing.current) {
        const deltaX = e.clientX - initialMouseX.current;
        setWidth(initialWidth.current + deltaX);
      }
    };

    const handleMouseUp = () => {
      isResizing.current = false;
      document.body.style.userSelect = "";
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (selectedConversationRef.current) {
      const bounding = selectedConversationRef.current.getBoundingClientRect();
      const isVisible =
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (!isVisible) {
        selectedConversationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [conversations]); // Scroll to the selected conversation when conversations are loaded or updated

  const handleMouseDown = (e: React.MouseEvent) => {
    isResizing.current = true;
    document.body.style.userSelect = "none";
    initialMouseX.current = e.clientX;
    initialWidth.current = width;
  };

  const handleConversationClick = (conversationId: string) => {
    navigate(`/dashboard/chats/${conversationId}`);
  };

  const filteredConversations = conversations.filter((conversation) => {
    const messagesContainQuery = conversation.messages.some((message) =>
      message.message.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const companyMatchesQuery = conversation.company
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return messagesContainQuery || companyMatchesQuery;
  });

  if (loading) {
    return (
      <div className={styles.chatsBar} style={{ width }}>
        <div className={styles.loadingContainer}>
          <div className={styles.spinner}></div>
          <div className={styles.loadingText}>Loading...</div>
        </div>
      </div>
    );
  }

  if (error) return <div>{error}</div>;

  return (
    <div style={{ display: "flex" }}>
      {isChatsBarVisible && (
        <div className={styles.chatsBar} style={{ width }}>
          <div className={styles.searchContainer}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchBar}
            />
            <button
              onClick={() => setIsChatsBarVisible(false)}
              className={`${styles.toggleButton} ${styles.hideButton}`}
              title="Hide"
            >
              &#9776;
            </button>
          </div>
          <div className={styles.conversationsList}>
            {filteredConversations.map((conversation) => {
              const lastMessage =
                conversation.messages[conversation.messages.length - 1];

              const formattedDate = lastMessage
                ? new Date(conversation.endTimestamp * 1000).toLocaleDateString(
                    "en-US",
                    {
                      month: "numeric",
                      day: "numeric",
                    }
                  )
                : "";

              const isSelected = location.pathname.includes(conversation._id);

              return (
                <div
                  key={conversation._id}
                  ref={isSelected ? selectedConversationRef : null} // Attach ref to the selected conversation
                  className={`${styles.conversation} ${
                    isSelected ? styles.selected : ""
                  }`}
                  onClick={() => handleConversationClick(conversation._id)}
                >
                  <div className={styles.lastMessageContainer}>
                    <div className={styles.lastMessage}>
                      {lastMessage ? lastMessage.message : "No messages yet"}
                    </div>
                    <div className={styles.messageDate}>{formattedDate}</div>
                  </div>
                </div>
              );
            })}
            <div
              className={styles.resizeHandle}
              onMouseDown={handleMouseDown}
            />
          </div>
        </div>
      )}
      {!isChatsBarVisible && (
        <button
          onClick={() => setIsChatsBarVisible(true)}
          className={`${styles.toggleButton} ${styles.showButton}`}
          title="Show"
        >
          &#x25B6;
        </button>
      )}
    </div>
  );
};

export default ChatsBar;
