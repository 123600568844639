import React from "react";
import AdminMessageContainer from "./AdminMessageContainer";
import styles from "./AdminChatDisplay.module.css";

import { Message } from "../../../types/types";

interface Props {
  messages: Message[];
  isRevision: boolean;
  onDeleteRevision?: (index: number) => void;
  selectedRevision: number;
  conversationId: string; // Ensure this is passed from parent component
  onMessagesUpdate: () => void;
}

const AdminChatDisplay: React.FC<Props> = ({
  messages,
  isRevision,
  onDeleteRevision,
  selectedRevision,
  conversationId, // Make sure to receive this prop
  onMessagesUpdate, // Ensure this is passed from parent component
}) => {
  const processedMessages = messages.flatMap((msg, index) => {
    const messageObjects = [{ ...msg, index }];
    if (isRevision && msg.sender === "user" && msg.reworded_message) {
      const rewordedMessage = {
        ...msg,
        _id: `${msg._id}_reworded`,
        message: msg.reworded_message,
        sender: "user_reworded",
        index,
      };
      messageObjects.push(rewordedMessage);
    }
    return messageObjects;
  });

  const handleDeleteRevision = () => {
    if (onDeleteRevision && selectedRevision !== -1) {
      onDeleteRevision(selectedRevision);
    }
  };

  console.log(processedMessages);

  return (
    <div className={styles.chatDisplay}>
      {isRevision && (
        <button
          className={styles.deleteRevisionButton}
          onClick={handleDeleteRevision}
        >
          Delete Revision
        </button>
      )}
      {processedMessages.map((msg, index) => (
        <AdminMessageContainer
          key={msg._id}
          message={msg}
          isRevision={isRevision}
          conversationId={conversationId} // Passing down the conversationId
          selectedRevision={selectedRevision} // Passing down the selectedRevision
          messageIndex={msg.index} // Pass the current message index
          onMessagesUpdate={onMessagesUpdate}
        />
      ))}
    </div>
  );
};

export default AdminChatDisplay;
