import React from "react";
import { useSearchParams, Link } from "react-router-dom";
import styles from "./EmailVerificationPage.module.css"; // Update the import path if necessary
import PontoAI from "../../assets/PontoAI.svg"; // Update the import path if necessary

const EmailVerificationPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const renderMessage = () => {
    switch (status) {
      case "success":
        return (
          <p className={`${styles.message} ${styles.success}`}>
            Your email has been successfully verified!
            <br />
            You can now login.
          </p>
        );
      case "invalid":
        return (
          <p className={`${styles.message} ${styles.error}`}>
            Invalid or expired token. <br />
            Please try the verification process again.
          </p>
        );
      case "error":
        return (
          <p className={`${styles.message} ${styles.error}`}>
            An error occurred during verification. <br /> Please try again
            later.
          </p>
        );
      default:
        return (
          <p className={styles.message}>
            Welcome to the email verification page.
          </p>
        );
    }
  };

  return (
    <div className={styles.verificationContainer}>
      <header className={styles.emailHeader}>Email Verification</header>
      <main className={styles.verificationContent}>
        <div className={styles.verificationBox}>
          <img src={PontoAI} alt="Ponto AI Logo" className={styles.emailLogo} />
          {renderMessage()}
          {status === "success" && (
            <Link to="/signin">
              <button className={styles.verificationButton}>Go to Login</button>
            </Link>
          )}
          {(status === "invalid" || status === "error") && (
            <Link to="/signup">
              <button className={styles.verificationButton}>Try Again</button>
            </Link>
          )}
        </div>
      </main>
      <footer className={styles.emailFooter}></footer>
    </div>
  );
};

export default EmailVerificationPage;
