import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import styles from "./UsageTab.module.css";
import moment from "moment";

interface AggregatedMessage {
  date: string;
  count: number;
}

interface AggregatedMessagesData {
  aggregatedMessages: AggregatedMessage[];
}

interface MessageCountData {
  messageCount: number;
  messageLimit: number;
}

interface PlanData {
  plan: {
    name: string;
    description?: string;
    price?: number;
  };
  renewDate: string;
  autoRenewal: boolean;
}

interface APIError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const UsageTab = () => {
  const [messageCountData, setMessageCountData] =
    useState<MessageCountData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [aggregatedData, setAggregatedData] = useState<AggregatedMessagesData>({
    aggregatedMessages: [],
  });
  const [planData, setPlanData] = useState<PlanData | null>(null);
  const [timeFrame, setTimeFrame] = useState("1 week");

  const navigate = useNavigate();

  const fetchUsageData = useCallback(async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.get(`${backendUrl}/api/usage`, {
        headers,
        params: { timeframe: timeFrame },
      });

      const formattedData = response.data.aggregatedMessages.map(
        (message: AggregatedMessage) => {
          const formattedDate = moment(message.date).isValid()
            ? moment(message.date).format("MM/DD")
            : "Invalid date";

          return {
            ...message,
            date: formattedDate,
          };
        }
      );

      setAggregatedData({
        aggregatedMessages: formattedData,
      });
    } catch (err: unknown) {
      const error = err as APIError;
      setError(error.response?.data?.message || "Failed to fetch usage data");
    }
  }, [timeFrame]);

  const fetchMessageCountData = useCallback(async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.get(`${backendUrl}/api/messageCount`, {
        headers,
      });

      setMessageCountData({
        messageCount: response.data.messageCount,
        messageLimit: response.data.messageLimit,
      });
    } catch (err: unknown) {
      const error = err as APIError;
      setError(
        error.response?.data?.message || "Failed to fetch message count data"
      );
    }
  }, []);

  const fetchPlanData = useCallback(async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.get(`${backendUrl}/billing/current_plan`, {
        headers,
      });

      setPlanData(response.data);
    } catch (err: unknown) {
      const error = err as APIError;
      setError(
        error.response?.data?.message || "Failed to fetch current plan data"
      );
    }
  }, []);

  useEffect(() => {
    fetchUsageData();
  }, [fetchUsageData, timeFrame]);

  useEffect(() => {
    fetchMessageCountData();
    fetchPlanData();
  }, [fetchMessageCountData, fetchPlanData]);

  const usagePercentage = messageCountData
    ? (messageCountData.messageCount / messageCountData.messageLimit) * 100
    : 0;

  const calculateInterval = (length: number) => {
    return Math.ceil(length / 7);
  };

  const handleUpgradeClick = () => {
    navigate("/dashboard/billing");
  };

  const handleTimeFrameClick = (frame: string) => {
    setTimeFrame(frame);
  };

  return (
    <div className={styles.usageContent}>
      <div className={styles.customerQueries}>
        <h3>Common customer queries</h3>
        <p>This feature is under development.</p>
        <div className={styles.comingSoonBadge}>Coming Soon!</div>
      </div>
      <div className={styles.usageContainer}>
        <h3 className={styles.usageTitle}>Usage</h3>
        <div className={styles.usageChart}>
          <div className={styles.usageChartsContainer}>
            <div className={styles.progressContainer}>
              <div className={styles.circularWrapper}>
                <CircularProgressbar
                  value={usagePercentage}
                  styles={buildStyles({
                    pathColor: "#0c7489",
                    trailColor: "#d6d6d6",
                  })}
                  className={styles.customProgress}
                />

                {messageCountData && (
                  <div className={styles.progressText}>
                    <div className={styles.countText}>
                      {messageCountData.messageCount}/
                      {messageCountData.messageLimit}
                    </div>
                    <div className={styles.labelText}>Messages</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.subscriptionContainer}>
            <h4>Subscription</h4>
            {planData && (
              <div className={styles.planDetails}>
                <p>
                  <strong>Current Plan:</strong> {planData.plan.name}
                </p>
                <p>
                  <strong>Renewal Date:</strong>{" "}
                  {moment(planData.renewDate).format("MM/DD/YY")}
                </p>
              </div>
            )}
            <button
              className={styles.upgradeButton}
              onClick={handleUpgradeClick}
            >
              Upgrade Plan
            </button>
          </div>
        </div>

        <div className={styles.usageGraph}>
          <div className={styles.timeFrameButtons}>
            <button
              className={
                timeFrame === "3 months"
                  ? `${styles.timeFrameButton} ${styles.timeButtonSelected}`
                  : styles.timeFrameButton
              }
              onClick={() => handleTimeFrameClick("3 months")}
            >
              3 Months
            </button>
            <button
              className={
                timeFrame === "1 month"
                  ? `${styles.timeFrameButton} ${styles.timeButtonSelected}`
                  : styles.timeFrameButton
              }
              onClick={() => handleTimeFrameClick("1 month")}
            >
              1 Month
            </button>
            <button
              className={
                timeFrame === "1 week"
                  ? `${styles.timeFrameButton} ${styles.timeButtonSelected}`
                  : styles.timeFrameButton
              }
              onClick={() => handleTimeFrameClick("1 week")}
            >
              1 Week
            </button>
          </div>

          <ResponsiveContainer width="100%" height="85%">
            <LineChart
              data={aggregatedData.aggregatedMessages}
              margin={{ top: 5, right: 30, left: 30, bottom: 5 }} // Add margins
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                interval={calculateInterval(
                  aggregatedData.aggregatedMessages.length
                )}
                stroke="#dadce0"
                tick={{ fontSize: 13, fontWeight: "bold" }}
                label={{
                  value: "Date",
                  position: "insideBottom",
                  dy: 10,
                  fill: "#dadce0",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              />
              <YAxis
                label={{
                  value: "Message Count",
                  angle: -90,
                  position: "insideLeft",
                  fill: "#dadce0",
                  dy: 45,
                  fontSize: 15,
                  fontWeight: "bold",
                }}
                stroke="#dadce0"
                tick={{ fontSize: 13, fontWeight: "bold" }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#4a6b7a",
                  borderColor: "#4a6b7a",
                }}
                labelStyle={{ color: "#ffffff" }}
                itemStyle={{ color: "#ffffff" }}
              />
              <Line
                type="linear"
                dataKey="count"
                stroke="#ffffff"
                strokeWidth={3}
                dot={false}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default UsageTab;
