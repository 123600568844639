import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Navbar";
import styles from "./SignInPage.module.css";
import EmailLogo from "../../assets/EmailLogo.svg";
import PasswordLogo from "../../assets/PasswordLogo.svg";
import ViewLogo from "../../assets/ViewLogo.svg";

const SignInPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const [showResendButton, setShowResendButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCooldown > 0) {
      timer = setInterval(() => {
        setResendCooldown(resendCooldown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendCooldown]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleSignInClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      console.error("SignIn Error: Both email and password must be provided");
      setErrorMessage("Please enter both email and password.");
      return;
    }

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const body = {
      email,
      password,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${backendUrl}/auth/signin`, body, {
        headers,
      });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userName", response.data.name);
      localStorage.setItem("userEmail", response.data.email);

      const userEmail = response.data.email;
      if (userEmail.endsWith("@pontoai.com")) {
        navigate("/dashboard");
      } else {
        const statusResponse = await axios.get(
          `${backendUrl}/api/chatbot-status`,
          {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          }
        );
        const { chatbotInitialized, chatbotScraped, chatbotActive } =
          statusResponse.data;

        if (!chatbotInitialized || !chatbotScraped || !chatbotActive) {
          navigate("/dashboard/setup");
        } else {
          navigate("/dashboard/chats");
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error(`SignIn Error: ${error.response.data.message}`);
        setErrorMessage(
          error.response.data.message || "An error occurred during sign-in"
        );

        if (error.response.data.message.includes("verify your email")) {
          setShowResendButton(true);
        }
      } else {
        console.error("SignIn Error: An unexpected error occurred");
        setErrorMessage("An unexpected error occurred");
      }
    }
  };

  const handleSignUpClick = () => {
    navigate("/signup");
    console.log("Navigating to sign-up page.");
  };

  const handleForgotPasswordClick = () => {
    navigate("/password");
    console.log("Navigating to forgot password page.");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    console.log(`Password visibility set to: ${showPassword}`);
  };

  const handleResendEmail = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/resend`, {
        email,
      });
      setResendCooldown(60);
      setSuccessMessage("Verification email resent successfully.");
      setErrorMessage("");
    } catch (error) {
      console.error("Error resending the email", error);
      setErrorMessage("Failed to resend verification email.");
    }
  };

  return (
    <div className={styles.signInPage}>
      <Navbar />
      <div className={styles.mainContent}>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Sign In</h1>
          <form className={styles.form} onSubmit={handleSignInClick}>
            <div className={styles.inputGroup}>
              <img src={EmailLogo} alt="User" className={styles.userIcon} />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.inputGroup}>
              <img
                src={PasswordLogo}
                alt="Password"
                className={styles.passwordIcon}
              />
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                required
                className={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={ViewLogo}
                alt="View"
                className={styles.viewIcon}
                onClick={togglePasswordVisibility}
              />
            </div>
            {errorMessage && (
              <div className={styles.errorText}>{errorMessage}</div>
            )}
            {successMessage && (
              <div className={styles.successText}>{successMessage}</div>
            )}
            <div className={styles.forgotPasswordContainer}>
              <button
                type="button"
                className={styles.linkButton}
                onClick={handleForgotPasswordClick}
              >
                Forgot Password?
              </button>
              {showResendButton && (
                <button
                  type="button"
                  className={styles.linkButton}
                  onClick={handleResendEmail}
                  disabled={resendCooldown > 0}
                >
                  {resendCooldown > 0
                    ? `Wait ${resendCooldown}s to resend...`
                    : "Resend Verification Email"}
                </button>
              )}
            </div>
            <button type="submit" className={styles.signInButton}>
              Sign In
            </button>
          </form>
          <div className={styles.signUpContainer}>
            <span className={styles.signUpText}>Need an account?</span>
            <button className={styles.signUpButton} onClick={handleSignUpClick}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
