import React, { useState } from "react";
import axios from "axios";
import styles from "./AdminUserInfoPage.module.css";
import AdminNavBar from "../AdminNavBar";

const AdminUserInfoPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [saveStatus, setSaveStatus] = useState("");
  const [companyKnowledgeBase, setCompanyKnowledgeBase] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [referenceUrls, setReferenceUrls] = useState<any[]>([]);
  const [newTopic, setNewTopic] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [chatbotStatuses, setChatbotStatuses] = useState({
    initialized: false,
    active: false,
    scraped: false,
  });
  const [nameAlias, setNameAlias] = useState("");

  const [pendingStatuses, setPendingStatuses] = useState({
    initialized: false,
    active: false,
    scraped: false,
  });

  const handleNameAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameAlias(event.target.value);
  };

  const handleCompanyEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyEmail(event.target.value);
  };

  const handleCompanyPhoneChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyPhone(event.target.value);
  };

  const handleAddReferenceUrl = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const response = await axios.post(
        "/admin/add-reference-url",
        {
          email,
          topic: newTopic,
          url: newUrl,
          description: newDescription,
        },
        {
          baseURL: backendUrl,
          headers,
        }
      );
      setReferenceUrls([...referenceUrls, response.data.newUrl]);
      setNewTopic("");
      setNewUrl("");
      setNewDescription("");
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
  };

  const handleUpdateReferenceUrl = async (
    id: string,
    topic: string,
    url: string,
    description: string
  ) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      await axios.put(
        `/admin/update-reference-url/${id}`,
        {
          topic,
          url,
          description,
        },
        {
          baseURL: backendUrl,
          headers,
        }
      );
      const updatedReferenceUrls = referenceUrls.map((referenceUrl) =>
        referenceUrl._id === id
          ? { ...referenceUrl, topic, url, description }
          : referenceUrl
      );
      setReferenceUrls(updatedReferenceUrls);
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
  };

  const handleDeleteReferenceUrl = async (id: string) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      await axios.delete(`/admin/delete-reference-url/${id}`, {
        baseURL: backendUrl,
        headers,
      });
      const updatedReferenceUrls = referenceUrls.filter(
        (referenceUrl) => referenceUrl._id !== id
      );
      setReferenceUrls(updatedReferenceUrls);
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
  };

  const fetchReferenceUrls = async (email: string) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      console.log("Making GET request to fetch reference URLs");
      const response = await axios.get(
        `admin/company-reference-urls/${email}`,
        {
          baseURL: backendUrl,
          headers,
        }
      );
      console.log("Received response:", response.data);
      setReferenceUrls(response.data);
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
  };

  const [apiKey, setApiKey] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const fetchCompanyData = async (email: string) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const response = await axios.get("/admin/user-company", {
        params: { email },
        baseURL: backendUrl,
        headers,
      });
      const {
        companyName,
        companyUrl,
        companyEmail,
        companyPhone,
        companyDescription,
        companyKnowledgeBase,
      } = response.data;
      setCompanyName(companyName);
      setCompanyUrl(companyUrl);
      setCompanyEmail(companyEmail);
      setCompanyPhone(companyPhone);
      setCompanyDescription(companyDescription);
      setCompanyKnowledgeBase(companyKnowledgeBase);

      // Fetch the chatbot statuses
      const statusResponse = await axios.get("/admin/user-api-key", {
        params: { email },
        baseURL: backendUrl,
        headers,
      });

      const { apiKey, chatbotInitialized, chatbotActive, chatbotScraped } =
        statusResponse.data;
      setApiKey(apiKey); // Set the apiKey state
      setChatbotStatuses({
        initialized: chatbotInitialized,
        active: chatbotActive,
        scraped: chatbotScraped,
      });
      setPendingStatuses({
        initialized: chatbotInitialized,
        active: chatbotActive,
        scraped: chatbotScraped,
      });
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
  };

  const handleCompanyKnowledgeBaseChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCompanyKnowledgeBase(event.target.value);
  };

  const handleSave = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const response = await axios.post(
        "/admin/update-company",
        {
          email,
          companyName,
          companyUrl,
          companyEmail,
          companyPhone,
          companyDescription,
          companyKnowledgeBase,
        },
        {
          baseURL: backendUrl,
          headers,
        }
      );

      await axios.post(
        "/admin/update-chatbot-status",
        {
          email,
          chatbotInitialized: pendingStatuses.initialized,
          chatbotActive: pendingStatuses.active,
          chatbotScraped: pendingStatuses.scraped,
        },
        {
          baseURL: backendUrl,
          headers,
        }
      );

      setSaveStatus("Save successful");
      console.log("Save successful", response.data);
      setChatbotStatuses(pendingStatuses); // Update the actual chatbot statuses
      setTimeout(() => {
        setSaveStatus(""); // This will clear the status after 2 seconds
      }, 2000);
    } catch (error) {
      const errorMsg = axios.isAxiosError(error)
        ? error.response?.data.message
        : "An unexpected error occurred";
      setSaveStatus(errorMsg);
      console.error(errorMsg);
      setTimeout(() => {
        setSaveStatus(""); // This will also clear the status after 2 seconds
      }, 2000);
    }
  };

  const handleSearch = () => {
    if (!email) {
      console.error("Please enter an email address");
      return;
    }
    console.log("Fetching company data for email:", email);
    fetchCompanyData(email);
    fetchReferenceUrls(email); // Add this line
  };

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
  };

  const handleCompanyUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyUrl(event.target.value);
  };

  const handleCompanyDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCompanyDescription(event.target.value);
  };

  const handleGenerateEmbedding = async () => {
    console.log("Generating embedding for:", companyName);
    setLoading(true); // Set loading to true to indicate process start

    const backendUrl = process.env.REACT_APP_PYTHON_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    try {
      const response = await axios.post(
        `${backendUrl}/create_collection`,
        {
          email,
          companyName,
          companyDescription,
          companyKnowledgeBase,
        },
        { headers }
      );
      console.log("Embedding generated:", response.data);
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
    setLoading(false); // Set loading to false regardless of the outcome
  };

  const handleStatusChange = (statusKey: keyof typeof chatbotStatuses) => {
    setPendingStatuses((prev) => ({ ...prev, [statusKey]: !prev[statusKey] }));
  };
  
  const handleCreateDemo = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    console.log("Creating demo with name_alias:", nameAlias);

    try {
      const response = await axios.post(
        "/admin/create-demo",
        {
          name_alias: nameAlias,
          apiKey,
          companyName,
        },
        {
          baseURL: backendUrl,
          headers,
        }
      );
      console.log(response.data.message, response.data.demo);
    } catch (error) {
      console.error(
        axios.isAxiosError(error)
          ? error.response?.data.message
          : "An unexpected error occurred"
      );
    }
  };

  return (
    <div className={styles.knowledgeBase}>
      <div className={styles.navBarDiv}>
        <AdminNavBar />
      </div>
      <div className={styles.infoSection}>
        <div className={styles.infoSectionLeft}>
          <div className={styles.searchSection}>
            <label htmlFor="email">Search User Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter user email"
            />
            <button onClick={handleSearch}>Search</button>
          </div>
          <div className={styles.createDemoSection}>
            <label htmlFor="nameAlias">Name Alias:</label>
            <input
              type="text"
              id="nameAlias"
              value={nameAlias}
              onChange={handleNameAliasChange}
              placeholder="Enter name alias"
            />
            <button onClick={handleCreateDemo}>Create Demo</button>
          </div>
          <div className={styles.infoItem}>
            <label htmlFor="companyName">Company Name:</label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={handleCompanyNameChange}
            />
          </div>
          <div className={styles.infoItem}>
            <label htmlFor="companyUrl">Company URL:</label>
            <input
              type="text"
              id="companyUrl"
              value={companyUrl}
              onChange={handleCompanyUrlChange}
            />
          </div>
          <div className={styles.infoItem}>
            <label htmlFor="companyEmail">Company Email:</label>
            <input
              type="email"
              id="companyEmail"
              value={companyEmail}
              onChange={handleCompanyEmailChange}
            />
          </div>
          <div className={styles.infoItem}>
            <label htmlFor="companyPhone">Company Phone:</label>
            <input
              type="tel"
              id="companyPhone"
              value={companyPhone}
              onChange={handleCompanyPhoneChange}
            />
          </div>
          <div className={styles.infoItem}>
            <label htmlFor="companyDescription">Company Description:</label>
            <textarea
              id="companyDescription"
              value={companyDescription}
              onChange={handleCompanyDescriptionChange}
              rows={5}
            />
          </div>
          <div className={styles.chatbotSection}>
            {Object.keys(chatbotStatuses).map((key) => (
              <div key={key} className={styles.chatbotContainer}>
                <label htmlFor={key} className={styles.label}>
                  Chatbot {key.charAt(0).toUpperCase() + key.slice(1)}:
                </label>
                <input
                  type="checkbox"
                  id={key}
                  checked={pendingStatuses[key as keyof typeof chatbotStatuses]}
                  onChange={() =>
                    handleStatusChange(key as keyof typeof chatbotStatuses)
                  }
                  className={styles.checkbox}
                />
              </div>
            ))}
          </div>
          <div className={styles.infoItem}>
            <label htmlFor="apiKey">API Key:</label>
            <input type="text" id="apiKey" value={apiKey} readOnly />
          </div>
        </div>

        <div className={styles.infoSectionRight}>
          <div className={styles.referenceUrlsSection}>
            <h3>Reference URLs</h3>
            {referenceUrls.map((referenceUrl) => (
              <div key={referenceUrl._id} className={styles.referenceUrl}>
                <input
                  type="text"
                  value={referenceUrl.topic}
                  onChange={(e) =>
                    handleUpdateReferenceUrl(
                      referenceUrl._id,
                      e.target.value,
                      referenceUrl.url,
                      referenceUrl.description
                    )
                  }
                />
                <input
                  type="text"
                  value={referenceUrl.url}
                  onChange={(e) =>
                    handleUpdateReferenceUrl(
                      referenceUrl._id,
                      referenceUrl.topic,
                      e.target.value,
                      referenceUrl.description
                    )
                  }
                />
                <input
                  type="text"
                  value={referenceUrl.description}
                  onChange={(e) =>
                    handleUpdateReferenceUrl(
                      referenceUrl._id,
                      referenceUrl.topic,
                      referenceUrl.url,
                      e.target.value
                    )
                  }
                />
                <button
                  onClick={() => handleDeleteReferenceUrl(referenceUrl._id)}
                >
                  Delete
                </button>
              </div>
            ))}
            <div className={styles.addReferenceUrl}>
              <input
                type="text"
                placeholder="Topic"
                value={newTopic}
                onChange={(e) => setNewTopic(e.target.value)}
              />
              <input
                type="text"
                placeholder="URL"
                value={newUrl}
                onChange={(e) => setNewUrl(e.target.value)}
              />
              <input
                type="text"
                placeholder="Description"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />
              <button onClick={handleAddReferenceUrl}>Add URL</button>
            </div>
          </div>
          <div
            className={styles.infoItem}
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="companyKnowledgeBase">Company KnowledgeBase:</label>
            <textarea
              id="companyKnowledgeBase"
              value={companyKnowledgeBase}
              onChange={handleCompanyKnowledgeBaseChange}
              rows={10}
              style={{ flexGrow: 1 }}
            />
            <div
              className={`${styles.saveStatus} ${
                saveStatus === "Save successful" ? styles.success : styles.error
              }`}
            >
              {saveStatus}
            </div>

            <div className={styles.buttonGroup}>
              <button className={styles.saveButton} onClick={handleSave}>
                Save
              </button>
              <button
                className={styles.generateButton}
                onClick={handleGenerateEmbedding}
                disabled={loading}
              >
                {loading ? "Generating Embeddings..." : "Generate Embedding"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserInfoPage;
