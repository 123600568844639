import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { BillingEntry } from "./Billing";
import styles from "./StripeCheckoutForm.module.css";

interface Plan {
  name: string;
  price: number;
}

interface Props {
  plan: Plan;
  onClose: () => void;
  updatePlan: (
    newPlan: string,
    newRenewDate: string,
    newBillingHistory: BillingEntry[]
  ) => void;
  autoRenewal: boolean;
  handleAutoRenewalChange: () => void;
}

function StripeCheckoutForm({
  plan,
  onClose,
  updatePlan,
  autoRenewal,
  handleAutoRenewalChange,
}: Props) {
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    console.log("Stripe:", stripe);
    console.log("Elements:", elements);
  }, [stripe, elements]);

  const renewDate = new Date();
  renewDate.setMonth(renewDate.getMonth() + 1);
  const formattedRenewDate = renewDate.toLocaleDateString();

  const handleClose = () => {
    setPaymentSuccess(false);
    onClose();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Form submitted");

    if (!stripe || !elements) {
      setErrorMessage("Stripe or Elements is not loaded.");
      console.log("Stripe or Elements is not loaded.");
      return;
    }

    const userEmail = localStorage.getItem("userEmail");
    if (!userEmail) {
      setErrorMessage("User email is required but not set.");
      console.log("User email is required but not set.");
      return;
    }

    setIsProcessing(true);
    setErrorMessage("");

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setErrorMessage("Card information is incomplete.");
      setIsProcessing(false);
      console.log("Card information is incomplete.");
      return;
    }

    try {
      // Create payment method
      const { error: createPaymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name,
          },
        });

      if (createPaymentMethodError) {
        setErrorMessage(
          createPaymentMethodError.message ||
            "Failed to create payment method. Please try again."
        );
        setIsProcessing(false);
        console.log(
          "Create Payment Method Error:",
          createPaymentMethodError.message ||
            "Failed to create payment method. Please try again."
        );
        return;
      }

      const paymentMethodId = paymentMethod.id;

      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      console.log("Backend URL:", backendUrl);
      console.log("Plan:", plan);
      console.log("User Email:", userEmail);
      console.log("Payment Method ID:", paymentMethodId);
      
      const response = await axios.post(
        `${backendUrl}/billing/create-subscription`,
        { plan, userEmail, paymentMethodId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Create Subscription Response:", response.data);

      const { clientSecret, subscriptionId } = response.data;

      // Fetch the latest status of the payment intent
      const paymentIntentResponse = await stripe.retrievePaymentIntent(
        clientSecret
      );
      const paymentIntent = paymentIntentResponse.paymentIntent;

      if (!paymentIntent) {
        setErrorMessage("Failed to retrieve payment intent. Please try again.");
        setIsProcessing(false);
        console.log("Failed to retrieve payment intent. Please try again.");
        return;
      }

      if (paymentIntent.status === "succeeded") {
        setPaymentSuccess(true);
        updatePlan(plan.name, formattedRenewDate, subscriptionId);
        setIsProcessing(false);
        console.log("Payment Successful:", paymentIntent);
      } else if (
        paymentIntent.status === "requires_action" ||
        paymentIntent.status === "requires_confirmation"
      ) {
        // Confirm the payment intent
        const { error, paymentIntent: confirmedPaymentIntent } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId,
          });

        if (error) {
          setErrorMessage(error.message || "Payment failed. Please try again.");
          setIsProcessing(false);
          console.log("Payment Error:", error);
          console.error("Payment Error Details:", error);
        } else if (
          confirmedPaymentIntent &&
          confirmedPaymentIntent.status === "succeeded"
        ) {
          setPaymentSuccess(true);
          updatePlan(plan.name, formattedRenewDate, subscriptionId);
          setIsProcessing(false);
          console.log("Payment Successful:", confirmedPaymentIntent);
        } else {
          setErrorMessage("Unexpected status in payment intent.");
          setIsProcessing(false);
          console.log(
            "Unexpected Payment Intent Status:",
            confirmedPaymentIntent
          );
        }
      } else {
        setErrorMessage(
          `Unexpected payment intent status: ${paymentIntent.status}.`
        );
        setIsProcessing(false);
        console.log(
          `Unexpected payment intent status: ${paymentIntent.status}.`
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          error.response?.data.message ||
          "There was an error processing your payment. Please try again.";
        setErrorMessage(message);
        console.log("Axios Error:", message);
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
        console.log("Unexpected Error:", error);
      }
      setIsProcessing(false);
    }
  };

  if (!stripe || !elements) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className={styles.formContainer}>
      <button type="button" className={styles.closeButton} onClick={onClose}>
        &times;
      </button>
      {paymentSuccess ? (
        <div className={styles.successContent}>
          <h2>Payment Successful!</h2>
          <p className={styles.highlight}>Thank you for your subscription.</p>
          <p>
            Your <span className={styles.highlight}>{plan.name}</span> plan is
            now active.
          </p>
          <p className={styles.subText}>
            Subscription:{" "}
            <span className={styles.highlight}>${plan.price}</span> /month
          </p>
          <p className={styles.subText}>
            Renew Date:{" "}
            <span className={styles.highlight}>{formattedRenewDate}</span>
          </p>
          <button onClick={handleClose} className={styles.closeButton}>
            Close
          </button>
        </div>
      ) : (
        <>
          <div className={styles.subscriptionDetails}>
            <div className={styles.subscriptionInfo}>
              <span className={styles.planLabel}>Subscription Plan:</span>
              <span className={styles.planName}>{plan.name}</span>
            </div>
            <div className={styles.subscriptionInfo}>
              <span className={styles.priceLabel}>Subscription:</span>
              <span className={styles.priceValue}>
                ${plan.price} billed monthly
              </span>
            </div>
            <div className={styles.subscriptionInfo}>
              <span className={styles.renewDateLabel}>Renew Date:</span>
              <span className={styles.renewDateValue}>
                {formattedRenewDate}
              </span>
            </div>
            <div className={styles.subscriptionInfo}>
              <span className={styles.planLabel}>Auto-renewal:</span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={autoRenewal}
                  onChange={handleAutoRenewalChange}
                />
                <span className={styles.slider}></span>
              </label>
            </div>
          </div>
          <div className={styles.inputRow}>
            <label className={styles.label}>Name on Card</label>
            <input
              name="name"
              placeholder="Name on Card"
              required
              onChange={(e) => setName(e.target.value)}
              className={styles.uniformInput}
            />
          </div>
          <div className={styles.inputRow}>
            <label className={styles.label}>Card Number</label>
            <CardNumberElement className={styles.cardElement} />
          </div>
          <div className={styles.expiryAndCvc}>
            <div className={styles.expiryWrapper}>
              <label className={styles.label}>Expiration Date</label>
              <CardExpiryElement className={styles.cardExpiryElement} />
            </div>
            <div className={styles.cvcWrapper}>
              <label className={styles.label}>CVC</label>
              <CardCvcElement className={styles.cardCvcElement} />
            </div>
          </div>
          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          <button
            type="submit"
            className={styles.payButton}
            disabled={!stripe || isProcessing}
          >
            {isProcessing ? "Processing..." : "Subscribe"}
          </button>
        </>
      )}
    </form>
  );
}

export default StripeCheckoutForm;
