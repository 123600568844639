import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import LandingSection from "./LandingSection";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";
import styles from "./LandingPage.module.css";

const LandingPage: React.FC = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");
    if (scrollTo === "about") {
      scrollToRef(aboutRef);
    } else if (scrollTo === "contact") {
      scrollToRef(contactRef);
    }
  }, [location.search]);

  return (
    <div>
      <Navbar
        onAboutClick={() => scrollToRef(aboutRef)}
        onContactClick={() => scrollToRef(contactRef)}
      />
      <div className={styles.landingContainer}>
        <div className={styles.landingSection}>
          <LandingSection />
        </div>
        <div ref={aboutRef} className={styles.aboutSection}>
          <AboutSection />
        </div>
        <div ref={contactRef} className={styles.contactSection}>
          <ContactSection />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
