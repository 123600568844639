import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LandingSection.module.css";

const LandingSection: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleInstallClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/signup");
    }
  };

  const handlePricingClick = () => {
    navigate("/pricing");
  };

  useEffect(() => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.addEventListener("load", () => setLoading(false));
    }
  }, []);

  return (
    <div className={styles.landingContainer}>
      <div className={styles.textSection}>
        <h1>
          Engage with your
          <br />
          customers 24/7
        </h1>
        <p>
          With our AI-powered chatbot
          <br />
          tailored to your business website
        </p>
        <p className={styles.pricingText}>
          Start for <span className={styles.priceHighlight}>$0</span> up to 500
          messages/month
        </p>
        <div className={styles.buttonContainer}>
          <button
            className={styles.getStartedButton}
            onClick={handleInstallClick}
          >
            Install
          </button>
          <button className={styles.pricingButton} onClick={handlePricingClick}>
            Pricing
          </button>
        </div>
      </div>
      <div className={styles.videoSection}>
        {loading && (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingSpinner}></div>
            <p>Video Loading...</p>
          </div>
        )}
        <iframe
          src={process.env.REACT_APP_MAIN_VIDEO}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ display: loading ? "none" : "block" }}
        ></iframe>
      </div>
    </div>
  );
};

export default LandingSection;
