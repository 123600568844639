import React, { useEffect, useState } from "react";
import axios from "axios";
import SetupScripts from "./SetupScripts";
import SupportDetails from "./SupportDetails";
import IntroQuestions from "./IntroQuestions";
import UrlDetails from "./UrlDetails";
import FileUploads from "./FileUploads";
import styles from "./SetupTab.module.css";

const SetupTab: React.FC = () => {
  const [selectedInstruction, setSelectedInstruction] = useState("SquareSpace");
  const [chatbotInitialized, setChatbotInitialized] = useState<boolean | null>(
    null
  );
  const [chatbotScraped, setChatbotScraped] = useState<boolean | null>(null);
  const [highlightMessage, setHighlightMessage] = useState(false);

  const videoLinks: { [key: string]: string } = {
    SquareSpace: process.env.REACT_APP_SQUARESPACE_VIDEO!,
    Shopify: process.env.REACT_APP_SHOPIFY_VIDEO!,
    Wix: process.env.REACT_APP_WIX_VIDEO!,
  };

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    const fetchChatbotStatus = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/chatbot-status`, {
          headers,
        });
        setChatbotInitialized(response.data.chatbotInitialized);
        setChatbotScraped(response.data.chatbotScraped);
      } catch (error) {
        console.error("Failed to fetch chatbot settings", error);
      }
    };

    fetchChatbotStatus();
  }, []);

  let chatbotMessage: JSX.Element | null = null;
  if (chatbotInitialized === false) {
    chatbotMessage = (
      <span>Please copy and paste the script to your website</span>
    );
  } else if (chatbotInitialized === true && chatbotScraped === false) {
    chatbotMessage = (
      <span>
        Our AI is learning about your company!
        <br />
        We will notify you and enable the chatbot once it's ready
      </span>
    );
  }

  useEffect(() => {
    if (highlightMessage) {
      const timer = setTimeout(() => setHighlightMessage(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [highlightMessage]);

  return (
    <div className={styles.setupTab}>
      <div className={styles.setupInstructions}>
        {chatbotMessage && (
          <div
            className={`${styles.chatbotMessage} ${
              highlightMessage ? styles.highlight : ""
            }`}
          >
            {chatbotMessage}
          </div>
        )}
        <div className={styles.setupScripts}>
          <SetupScripts
            chatbotInitialized={chatbotInitialized}
            chatbotScraped={chatbotScraped}
            highlightChatbotMessage={() => {
              console.log("Highlight function called");
              setHighlightMessage(true);
            }}
          />
        </div>
        <div className={styles.setupVideo}>
          <div className={styles.videoOptions}>
            <label className={styles.videoLabel} htmlFor="instructions">
              Setup Guide
            </label>
            <select
              id="instructions"
              className={styles.dropdown}
              value={selectedInstruction}
              onChange={(e) => setSelectedInstruction(e.target.value)}
            >
              <option value="SquareSpace">SquareSpace</option>
              <option value="Shopify">Shopify</option>
              <option value="Wix">Wix</option>
            </select>
          </div>
          <div className={styles.videoWrapper}>
            <div className={styles.responsiveVideo}>
              <iframe
                className={styles.youtubeVideo}
                src={videoLinks[selectedInstruction]}
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.setupDetails}>
        <div className={styles.supportDetails}>
          <SupportDetails />
        </div>
        <div className={styles.introQuestions}>
          <IntroQuestions />
        </div>
        <div className={styles.urlDetails}>
          <UrlDetails />
        </div>
        <div className={styles.fileUploads}>
          <FileUploads />
        </div>
      </div>
    </div>
  );
};

export default SetupTab;
