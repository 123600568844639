// components/DashboardPages/DashboardBar.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./DashboardBar.module.css";

const DashboardBar: React.FC = () => {
  return (
    <div className={styles.dashboardBar}>
      <NavLink
        to="/dashboard/chats"
        className={({ isActive }) =>
          isActive ? `${styles.link} ${styles.activeLink}` : styles.link
        }
      >
        Chats
      </NavLink>
      <NavLink
        to="/dashboard/setup"
        className={({ isActive }) =>
          isActive ? `${styles.link} ${styles.activeLink}` : styles.link
        }
      >
        Setup
      </NavLink>
      <NavLink
        to="/dashboard/usage"
        className={({ isActive }) =>
          isActive ? `${styles.link} ${styles.activeLink}` : styles.link
        }
      >
        Usage
      </NavLink>
      <NavLink
        to="/dashboard/billing"
        className={({ isActive }) =>
          isActive ? `${styles.link} ${styles.activeLink}` : styles.link
        }
      >
        Billing
      </NavLink>
    </div>
  );
};

export default DashboardBar;
