import React from "react";
import styles from "./AdminConversationsPage.module.css";
import AdminNavBar from "../AdminNavBar";
import AdminCompanyBar from "./AdminCompanyBar";
import AdminConversations from "./AdminConversations";
import AdminChat from "./AdminChat";

const AdminConversationsPage: React.FC = () => {
  return (
    <div className={styles.adminConversationsParent}>
      <AdminNavBar />
      <div className={styles.adminConversationsComponent}>
        <div className={styles.adminCompanyBarContainer}>
          <AdminCompanyBar />
        </div>
        <div className={styles.adminConversationsContainer}>
          <AdminConversations />
        </div>
        <div className={styles.adminChatContainer}>
          <AdminChat />
        </div>
      </div>
    </div>
  );
};

export default AdminConversationsPage;
