import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { splitMessage, processHyperLink } from "./messageUtils"; // Adjust the path as necessary

import styles from "./ChatsArea.module.css";

interface Message {
  _id: string;
  sender: string;
  message: string;
  timestamp: number;
}

interface Conversation {
  _id: string;
  company: string;
  messages: Message[];
}

const ChatsArea: React.FC = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchConversation = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/convo/conversations/${conversationId}`,
          { cancelToken: source.token }
        );
        setConversation(response.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (axios.isCancel(err)) {
            console.log("Request canceled:", (err as AxiosError).message);
          } else {
            setError(
              `Failed to fetch conversation: ${(err as AxiosError).message}`
            );
          }
        } else {
          setError("Failed to fetch conversation: An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    if (conversationId) {
      fetchConversation();
    }

    return () => {
      source.cancel("Component unmounted, request canceled.");
      setLoading(false); // Reset loading state
    };
  }, [conversationId]);

  const getSenderLabel = (sender: string) => {
    if (sender === "user") {
      return "Customer";
    } else if (sender === "bot") {
      return "PontoAI";
    }
    return sender;
  };

  const formatDateForMessage = (timestamp: number) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  if (loading) return <div className={styles.spinner}></div>;
  if (error) return <div>{error}</div>;

  let lastSender = ""; // Track the sender of the last message to determine when to show the label

  return (
    <div className={styles.chatsArea}>
      {conversation ? (
        conversation.messages.map((message, messageIndex) => {
          const showSenderLabel = message.sender !== lastSender;
          lastSender = message.sender;

          const messagesToRender =
            message.sender === "bot"
              ? splitMessage(processHyperLink(message.message))
              : [message.message];

          return (
            <div
              key={`${message._id}-${messageIndex}`}
              className={`${styles.messageContainer} ${
                message.sender === "user"
                  ? styles.userContainer
                  : styles.botContainer
              }`}
            >
              {showSenderLabel && (
                <div
                  className={`${styles.senderLabel} ${
                    message.sender === "user"
                      ? styles.userLabel
                      : styles.botLabel
                  }`}
                >
                  {getSenderLabel(message.sender)}
                </div>
              )}
              {messagesToRender.map((msg, index) => (
                <div
                  key={`${message._id}-${index}`}
                  className={`${styles.message} ${
                    message.sender === "user"
                      ? styles.userMessage
                      : styles.botMessage
                  }`}
                >
                  <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: msg }} // Render as HTML
                  ></div>
                  <div className={styles.timestamp}>
                    {formatDateForMessage(message.timestamp)}
                  </div>
                </div>
              ))}
            </div>
          );
        })
      ) : (
        <div>No conversation selected.</div>
      )}
    </div>
  );
};

export default ChatsArea;
