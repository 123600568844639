import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Navbar.module.css";
import PontoAIHeader from "../assets/PontoAIHeader.svg";

interface NavbarProps {
  onAboutClick?: () => void;
  onContactClick?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onAboutClick, onContactClick }) => {
  const userName = localStorage.getItem("userName");
  const isLoggedIn = !!userName;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [chatbotStatus, setChatbotStatus] = useState({
    chatbotInitialized: false,
    chatbotScraped: false,
    chatbotActive: false,
  });
  const hideTimeout = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    const fetchChatbotStatus = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/chatbot-status`, {
          headers,
        });
        setChatbotStatus({
          chatbotInitialized: response.data.chatbotInitialized,
          chatbotScraped: response.data.chatbotScraped,
          chatbotActive: response.data.chatbotActive,
        });
      } catch (error) {
        console.error("Failed to fetch chatbot settings", error);
      }
    };

    if (isLoggedIn) {
      fetchChatbotStatus();
    }
  }, [isLoggedIn]);

  const handleMouseEnter = () => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    hideTimeout.current = setTimeout(() => {
      setDropdownVisible(false);
    }, 200);
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    window.location.href = "/signin"; // Force a redirect to the sign-in page
  };

  const handleDashboardClick = () => {
    if (
      !chatbotStatus.chatbotInitialized ||
      !chatbotStatus.chatbotScraped ||
      !chatbotStatus.chatbotActive
    ) {
      navigate("/dashboard/setup");
    } else {
      navigate("/dashboard/chats");
    }
  };

  const handleNavClick = (section: string) => {
    if (window.location.pathname !== "/") {
      navigate(`/?scrollTo=${section}`);
    } else {
      if (section === "about" && onAboutClick) onAboutClick();
      if (section === "contact" && onContactClick) onContactClick();
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={PontoAIHeader} alt="Ponto AI" />
        </Link>
      </div>
      <div className={styles.navLinks}>
        <a href="#home" onClick={handleHomeClick}>
          Home
        </a>
        <a href="#about" onClick={() => handleNavClick("about")}>
          About
        </a>
        <a href="#contact" onClick={() => handleNavClick("contact")}>
          Contact
        </a>
      </div>
      <div className={styles.authButtons}>
        {isLoggedIn ? (
          <div
            className={styles.profileContainer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleDashboardClick} // Clicking the profile or dashboard navigates based on chatbot status
          >
            <div className={styles.profileIcon}>
              {userName?.charAt(0).toUpperCase()}
            </div>
            {dropdownVisible && (
              <div className={styles.dropdownMenu}>
                <button onClick={handleDashboardClick} className={styles.link}>
                  Dashboard
                </button>
                <button
                  onClick={handleSignOut}
                  className={`${styles.link} ${styles.signOutButton}`}
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <Link to="/signin" className={styles.signin}>
              Sign In
            </Link>
            <Link to="/signup" className={styles.signup}>
              Sign Up
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
