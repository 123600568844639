import React, { useState, useRef, useEffect, RefObject } from "react";
import {
  FaThumbsUp,
  FaThumbsDown,
  FaTrash,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import styles from "./AdminMessageContainer.module.css";

import { Message } from "../../../types/types";

interface Props {
  message: Message;
  isRevision: boolean;
  conversationId: string;
  selectedRevision: number;
  messageIndex: number;
  onMessagesUpdate: () => void;
}

function useOutsideClick(
  ref: RefObject<HTMLTextAreaElement>,
  callback: () => void,
  saveRef: RefObject<HTMLDivElement>
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (!saveRef.current || !saveRef.current.contains(event.target as Node))
      ) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, saveRef]);
}

const AdminMessageContainer: React.FC<Props> = ({
  message,
  isRevision,
  conversationId,
  selectedRevision,
  messageIndex,
  onMessagesUpdate,
}) => {
  console.log("inside message container");
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingContext, setIsAddingContext] = useState(false);
  const [isAddingURL, setIsAddingURL] = useState(false);
  const [contextMessage, setContextMessage] = useState("");
  const [editText, setEditText] = useState("");
  const [editTopic, setEditTopic] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const iconContainerRef = useRef<HTMLDivElement>(null);
  const contextRef = useRef<HTMLTextAreaElement>(null);
  const [positivity, setPositivity] = useState(message.positive);
  const [isDeleted, setIsDeleted] = useState(false);

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  useOutsideClick(
    textAreaRef,
    () => {
      if (isEditing) handleCancel();
    },
    iconContainerRef
  );

  useEffect(() => {
    if (typeof message.message !== "string") {
      setEditTopic(message.message.topic);
      setEditUrl(message.message.url);
      setEditDescription(message.message.description);
    }
  }, [isEditing, message.message]); // Added message.message to the dependency array

  useEffect(() => {
    setPositivity(message.positive); // This will ensure that initial state is set correctly when component mounts or message changes
  }, [message]);

  const handleDoubleClick = () => {
    if (!isRevision) {
      return; // Prevent editing if it's not a revision
    }

    setIsEditing(true);
    if (typeof message.message === "string") {
      setEditText(message.message);
    } else {
      setEditTopic(message.message.topic);
      setEditUrl(message.message.url);
      setEditDescription(message.message.description);
    }
  };

  const handleSave = async () => {
    let url = "";
    let body = {};

    if (isRewordedMessage(message)) {
      url = `${
        process.env.REACT_APP_BACKEND_URL
      }/adminConvo/update-reworded-message-in-revision/${conversationId}/${selectedRevision}/${message._id.replace(
        "_reworded",
        ""
      )}`;
      body = { updatedMessage: { reworded_message: editText } };
    } else if (isURLMessage(message)) {
      url = `${process.env.REACT_APP_BACKEND_URL}/adminConvo/update-url-message-in-revision/${conversationId}/${selectedRevision}/${message._id}`;
      body = {
        updatedUrl: {
          topic: editTopic,
          url: editUrl,
          description: editDescription,
        },
      };
    } else {
      url = `${process.env.REACT_APP_BACKEND_URL}/adminConvo/update-message-in-revision/${conversationId}/${selectedRevision}/${message._id}`;
      body = { updatedMessage: { message: editText } };
    }

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Failed to save message edits");
      }

      if (typeof message.message === "string") {
        message.message = editText;
      } else {
        message.message.topic = editTopic;
        message.message.url = editUrl;
        message.message.description = editDescription;
      }
      setIsEditing(false);
      await onMessagesUpdate();
    } catch (error) {
      console.error("Failed to save message edits:", error);
    }
  };

  const isRewordedMessage = (message: Message) => {
    return message.sender === "user_reworded";
  };

  const isURLMessage = (message: Message) => {
    return message.sender === "url";
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleAddContext = () => {
    setIsAddingContext(true);
  };

  const handleAddURL = () => {
    setIsAddingURL(true);
  };

  const handleSaveContext = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/adminConvo/add-context-in-revision/${conversationId}/${selectedRevision}/${messageIndex}`;
    const body = { contextMessage };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Failed to save context message");
      }

      setIsAddingContext(false);
      await onMessagesUpdate(); // Call the function after successfully updating the context
    } catch (error) {
      console.error("Failed to save context message:", error);
    }
  };

  const handleSaveURL = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/adminConvo/add-url-in-revision/${conversationId}/${selectedRevision}/${messageIndex}`;
    const body = {
      urlMessage: {
        topic: editTopic,
        url: editUrl,
        description: editDescription || "",
      },
    };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Failed to save URL message");
      }

      setIsAddingURL(false);
      await onMessagesUpdate(); // Call the function after successfully updating the URL
    } catch (error) {
      console.error("Failed to save URL message:", error);
    }
  };

  const handleCancelContext = () => {
    setIsAddingContext(false);
  };

  const handleCancelURL = () => {
    setIsAddingURL(false);
  };

  const handleDelete = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/adminConvo/delete-message-in-revision/${conversationId}/${selectedRevision}/${message._id}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete message");
      }

      setIsDeleted(true); // Mark the message as deleted in the local state
      console.log("Message deleted successfully");
      await onMessagesUpdate(); // Call the function after successfully deleting the message
    } catch (error) {
      console.error("Failed to delete message:", error);
    }
  };

  const renderContextInput = () => (
    <div className={styles.contextInputContainer}>
      <textarea
        ref={contextRef}
        value={contextMessage}
        onChange={(e) => setContextMessage(e.target.value)}
        placeholder="Add context here..."
        className={styles.contextTextArea}
      />
      <div className={styles.contextSaveButtons}>
        <button
          onClick={handleSaveContext}
          className={styles.contextSaveButton}
        >
          Save
        </button>
        <button
          onClick={handleCancelContext}
          className={styles.contextSaveButton}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const renderURLInput = () => (
    <div className={styles.contextInputContainer}>
      <input
        value={editTopic}
        onChange={(e) => setEditTopic(e.target.value)}
        placeholder="Topic"
        className={styles.editInput}
      />
      <input
        value={editUrl}
        onChange={(e) => setEditUrl(e.target.value)}
        placeholder="URL"
        className={styles.editInput}
      />
      <textarea
        value={editDescription}
        onChange={(e) => setEditDescription(e.target.value)}
        placeholder="Description"
        className={styles.editTextArea}
      />
      <div className={styles.contextSaveButtons}>
        <button onClick={handleSaveURL} className={styles.contextSaveButton}>
          Save
        </button>
        <button onClick={handleCancelURL} className={styles.contextSaveButton}>
          Cancel
        </button>
      </div>
    </div>
  );

  const formatMessageContent = (msg: Message) => {
    if (isEditing) {
      return typeof msg.message === "string" ? (
        <>
          <textarea
            ref={textAreaRef}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            className={styles.editTextArea}
          />
          <div ref={iconContainerRef} className={styles.editIcons}>
            <FaCheck className={styles.iconCheck} onClick={handleSave} />
            <FaTimes
              className={styles.iconCancel}
              onClick={() => setIsEditing(false)}
            />
          </div>
        </>
      ) : (
        <div>
          <input
            value={editTopic}
            onChange={(e) => setEditTopic(e.target.value)}
            placeholder="Topic"
            className={styles.editInput}
          />
          <input
            value={editUrl}
            onChange={(e) => setEditUrl(e.target.value)}
            placeholder="URL"
            className={styles.editInput}
          />
          <textarea
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            placeholder="Description"
            className={styles.editTextArea}
          />
          <div ref={iconContainerRef} className={styles.editIcons}>
            <FaCheck className={styles.iconCheck} onClick={handleSave} />
            <FaTimes
              className={styles.iconCancel}
              onClick={() => setIsEditing(false)}
            />
          </div>
        </div>
      );
    } else if (typeof msg.message === "string") {
      return msg.message;
    } else {
      return (
        <div>
          <div>
            <strong>Topic:</strong> {msg.message.topic}
          </div>
          <div>
            <strong>URL:</strong>{" "}
            <a href={msg.message.url} target="_blank" rel="noopener noreferrer">
              {msg.message.url}
            </a>
          </div>
          <div>
            <strong>Description:</strong> {msg.message.description}
          </div>
        </div>
      );
    }
  };

  const getSenderLabelClass = (sender: string) => {
    return sender === "user" || sender === "user_reworded"
      ? styles.senderLabelRight
      : styles.senderLabelLeft;
  };

  const getSenderLabel = (sender: string) => {
    switch (sender) {
      case "user":
        return "User";
      case "user_reworded":
        return "User (Reworded)";
      case "bot":
        return "Bot";
      case "url":
        return "URL";
      case "context":
        return "Context";
      default:
        return sender;
    }
  };

  const handleThumbsUp = async () => {
    await updatePositivity(true);
  };

  const handleThumbsDown = async () => {
    await updatePositivity(false);
  };

  const updatePositivity = async (positive: boolean) => {
    setPositivity(positive); // Update state immediately for responsive UI
    const url = `${process.env.REACT_APP_BACKEND_URL}/adminConvo/update-message-positivity/${conversationId}/${selectedRevision}/${message._id}`;
    const body = { positive };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Failed to update message positivity");
      }
      // No need to update the local state here as it's already done
    } catch (error) {
      console.error("Failed to update message positivity:", error);
    }
  };

  const renderRevisionIcons = () => (
    <div className={styles.revisionIcons}>
      <FaThumbsUp
        className={`${styles.icon} ${
          positivity === true ? styles.iconPositive : ""
        }`}
        onClick={handleThumbsUp}
      />
      <FaThumbsDown
        className={`${styles.icon} ${
          positivity === false ? styles.iconNegative : ""
        }`}
        onClick={handleThumbsDown}
      />
      <FaTrash className={styles.icon} onClick={handleDelete} />
    </div>
  );

  if (isDeleted) {
    return null; // Do not render the component if the message is deleted
  }

  return (
    <div className={styles.messageWrapper}>
      {isAddingContext && message.sender === "bot" && renderContextInput()}
      {isAddingURL && message.sender === "bot" && renderURLInput()}

      <div
        className={`${styles.senderLabel} ${getSenderLabelClass(
          message.sender
        )}`}
      >
        {getSenderLabel(message.sender)}
        {isRevision && message.sender === "bot" && (
          <div className={styles.contextButtons}>
            <button onClick={handleAddContext} className={styles.contextButton}>
              Add Context
            </button>
            <button onClick={handleAddURL} className={styles.contextButton}>
              Add URL
            </button>
          </div>
        )}
      </div>
      <div
        className={`${styles.messageContainer} ${
          styles[message.sender.replace("user_reworded", "user")]
        }`}
      >
        <div
          className={`${styles.messageContent} ${
            isEditing ? styles.editing : ""
          }`}
          onDoubleClick={handleDoubleClick}
        >
          <span className={styles.messageText}>
            {formatMessageContent(message)}
          </span>
          {!isRevision &&
            (message.sender === "user" || message.sender === "bot") && (
              <span className={styles.messageTimestamp}>
                {formatDate(message.timestamp)}
              </span>
            )}
          {isRevision && (
            <div className={styles.revisionIcons}>
              {message.sender === "user" ||
              message.sender === "user_reworded" ||
              message.sender === "bot" ? (
                <FaTrash className={styles.icon} onClick={handleDelete} />
              ) : (
                <>{renderRevisionIcons()}</>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminMessageContainer;
