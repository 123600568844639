import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import DashboardBar from "./DashboardBar";
import styles from "./Dashboard.module.css";

const Dashboard: React.FC = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail && userEmail.endsWith("@pontoai.com")) {
      setIsAdmin(true);
      navigate("/admin/conversations");
    } else {
      setIsAdmin(false);
    }
  }, [navigate]);

  if (isAdmin) {
    return null;
  }

  return (
    <div className={styles.dashboard}>
      <Navbar />
      <div className={styles.dashboardContent}>
        <DashboardBar />
        <div className={styles.outletContainer}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
