import React, { useState } from "react";
import { FaPaperPlane } from "react-icons/fa";
import styles from "./AdminChatSend.module.css";

interface Props {
  conversationId: string;
}

const AdminChatSend: React.FC<Props> = ({ conversationId }) => {
  const [message, setMessage] = useState("");

  const sendMessage = async () => {
    if (message.trim() === "") return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminConvo/send-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ conversationId, message }),
      });

      if (!response.ok) {
        throw new Error("Error sending message");
      }

      setMessage(""); // Clear the input field after sending
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  return (
    <div className={styles.sendContainer}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message"
        className={styles.input}
      />
      <button onClick={sendMessage} className={styles.sendButton}>
        <FaPaperPlane />
      </button>
    </div>
  );
};

export default AdminChatSend;
