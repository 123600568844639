import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./DemoPage.module.css";
import Navbar from "../Navbar";

const DemoPage: React.FC = () => {
  const { nameAlias } = useParams<{ nameAlias: string }>();
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchDemoDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/demo/${nameAlias}`
        );
        if (!response.ok) {
          throw new Error("Company not found");
        }
        const data = await response.json();
        setCompanyName(data.companyName);
        setApiKey(data.apiKey);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDemoDetails();
  }, [nameAlias]);

  useEffect(() => {
    if (apiKey) {
      const script = document.createElement("script");
      script.src = "https://static.pontoai.com/ChatUI.js";
      script.async = true;
      script.id = "chat-script";
      script.setAttribute("api_key", apiKey);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [apiKey]);

  return (
    <div>
      <Navbar />
      <div className={styles.container}>
        <h1 className={styles.title}>
          {companyName}
          <br />
          Chatbot Demo
        </h1>
        <p className={styles.description}>
          Explore and interact with a chatbot designed for your company.
        </p>
      </div>
    </div>
  );
};

export default DemoPage;
