import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../Navbar";
import styles from "./PasswordUpdatePage.module.css";

const PasswordUpdatePage: React.FC = () => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isUpdated, setIsUpdated] = useState(false); // New state variable
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const email = query.get("email");

  const handlePasswordUpdateClick = async () => {
    if (!password) {
      setErrorMessage("Please enter your new password.");
      return;
    }

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const body = { email, token, password };
    const headers = { "Content-Type": "application/json" };

    try {
      await axios.post(`${backendUrl}/auth/update-password`, body, { headers });
      setSuccessMessage("Password updated successfully. Please sign in with your new password.");
      setErrorMessage("");
      setIsUpdated(true); // Set the state to true on success
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message || "An error occurred during password update.");
        setSuccessMessage("");
      } else {
        setErrorMessage("An unexpected error occurred.");
        setSuccessMessage("");
      }
    }
  };

  const handleSignInClick = () => {
    navigate("/signin");
  };

  return (
    <div className={styles.passwordUpdatePage}>
      <Navbar />
      <div className={styles.mainContent}>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Update Password</h1>
          <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
            <div className={styles.inputGroup}>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="New Password"
                required
                className={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
            {successMessage && <div className={styles.successText}>{successMessage}</div>}
            {!isUpdated && ( // Conditionally render the button
              <button
                type="button"
                className={styles.updateButton}
                onClick={handlePasswordUpdateClick}
              >
                Update Password
              </button>
            )}
          </form>
          <button
            type="button"
            className={styles.signInButton}
            onClick={handleSignInClick}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdatePage;
