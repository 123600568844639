// components/PrivateRoute.tsx
import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  element: ComponentType<any>;
  [key: string]: any;  // To allow for additional props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
