import React from "react";
import styles from "./TermsOfService.module.css";

interface TermsOfServiceProps {
  onAgree: () => void;
  onCancel: () => void;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({
  onAgree,
  onCancel,
}) => {
  return (
    <div className={styles.termsContainer}>
      <h1 className={styles.header}>Terms of Service</h1>
      <p className={styles.updateDate}>Last updated: July 28, 2024</p>
      <div className={styles.contentContainer}>
        <p className={styles.content}>
          Welcome to PontoAI. By accessing or using our services, you agree to
          be bound by the following Terms of Service ("Terms"). Please read
          these Terms carefully prior to using our services.
        </p>
        <p className={styles.content}>
          1. <b>Service Description:</b> PontoAI provides an advanced AI-powered
          chatbot solution intended to facilitate customer support for
          businesses. Our chatbot engages with customers to provide automated
          assistance and enhance customer interactions. The services provided by
          PontoAI are subject to the terms and conditions set forth herein.
        </p>
        <p className={styles.content}>
          2. <b>User Obligations:</b> By using PontoAI, you agree to comply with
          all applicable laws and regulations. You agree not to use the services
          for any unlawful purpose or in any way that might harm, damage, or
          disparage any other party. You are responsible for maintaining the
          confidentiality of your account information, including your password,
          and for all activities that occur under your account. You agree to
          notify PontoAI immediately of any unauthorized use of your account or
          any other breach of security.
        </p>
        <p className={styles.content}>
          3. <b>Account Termination:</b> Users may terminate their accounts at
          any time. Upon termination, all personal data will be expunged from
          our databases. However, conversation data from the chatbot may be
          retained for service enhancement purposes. PontoAI reserves the right
          to terminate or suspend your account without prior notice or
          liability, for any reason whatsoever, including without limitation if
          you breach the Terms. Upon termination, your right to use the service
          will cease immediately.
        </p>
        <p className={styles.content}>
          4. <b>Billing and Refunds:</b> Users will be billed on a monthly basis
          in accordance with their selected subscription plan. Payment must be
          made by the designated due date to avoid interruption of services.
          Refund requests must be submitted within fourteen (14) days of the
          specific payment date. Refunds will not be issued beyond this period.
          PontoAI reserves the right to modify its billing and refund policies
          at any time, with or without notice to users.
        </p>
        <p className={styles.content}>
          5. <b>Intellectual Property:</b> All content, trademarks, and data on
          PontoAI are the property of their respective owners and are protected
          by intellectual property laws. Users are granted a limited,
          non-exclusive, non-transferable license to access and use the services
          in accordance with these Terms. Any unauthorized use of the content,
          trademarks, or data is strictly prohibited and may result in
          termination of the license and legal action.
        </p>
        <p className={styles.content}>
          6. <b>Privacy and Data Collection:</b> PontoAI is committed to
          protecting your privacy. PontoAI collects conversation data from
          chatbot interactions to enhance and improve our services. The data
          collected is used solely for the purpose of improving the chatbot's
          performance and user experience. We do not sell any personal
          information collected from our users. Please be advised that while
          user data will be deleted upon account termination, conversation data
          from the chatbot may be retained for the purpose of ongoing service
          improvements. By using our services, you consent to the collection and
          use of this data in accordance with these Terms.
        </p>
        <p className={styles.content}>
          7. <b>Disclaimer of Warranties and Limitation of Liability:</b>{" "}
          PontoAI strives to ensure the safety and accuracy of the chatbot
          responses. However, PontoAI does not warrant or guarantee the
          accuracy, reliability, or completeness of any information provided by
          the chatbot. Users acknowledge that the use of PontoAI’s services is
          at their own risk. PontoAI disclaims all warranties, express or
          implied, including but not limited to the implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement. PontoAI shall not be liable for any direct,
          indirect, incidental, special, or consequential damages, including but
          not limited to damages for loss of profits, goodwill, use, data, or
          other intangible losses, resulting from (i) the use or the inability
          to use the service; (ii) any conduct or content of any third party on
          the service; (iii) any content obtained from the service; and (iv)
          unauthorized access, use, or alteration of your transmissions or
          content, whether based on warranty, contract, tort (including
          negligence), or any other legal theory, even if PontoAI has been
          advised of the possibility of such damages.
        </p>
        <p className={styles.content}>
          8. <b>Indemnification:</b> You agree to indemnify, defend, and hold
          harmless PontoAI, its affiliates, officers, directors, employees,
          agents, and licensors from and against any claims, liabilities,
          damages, losses, costs, or expenses, including reasonable attorneys’
          fees, arising out of or in any way connected with your access to or
          use of the services, your violation of these Terms, or your
          infringement of any intellectual property or other rights of any third
          party.
        </p>
        <p className={styles.content}>
          9. <b>Modifications to Terms:</b> PontoAI reserves the right to amend
          these Terms at any time. Significant changes will be communicated to
          users via email or through our platform. Your continued use of the
          services following the posting of any changes to these Terms
          constitutes acceptance of those changes. It is your responsibility to
          review these Terms periodically for any updates or changes.
        </p>
        <p className={styles.content}>
          10. <b>Payment Terms:</b> Subscription fees are billed on a monthly
          basis per the terms of the chosen subscription plan. Pricing is
          subject to modification. Users will be notified of any changes to
          subscription fees. Failure to pay subscription fees when due may
          result in the suspension or termination of your account.
        </p>
        <p className={styles.content}>
          11. <b>Marketing and Promotional Use:</b> By utilizing PontoAI’s
          services, you grant PontoAI the right to use your company's name,
          logo, website, and other related information for marketing and
          promotional purposes. This includes, but is not limited to, use in
          product demonstration videos, advertisements, and promotional
          materials. You agree that PontoAI may use these materials without
          compensation or further permission.
        </p>
        <p className={styles.content}>
          12. <b>Governing Law:</b> These Terms shall be governed by and
          construed in accordance with the laws of the State of California,
          without regard to its conflict of laws principles. Any disputes
          arising out of or relating to these Terms or the services provided by
          PontoAI shall be resolved in the state or federal courts located in
          California, and you consent to the exclusive jurisdiction and venue of
          such courts.
        </p>
        <p className={styles.content}>
          13. <b>Severability:</b> If any provision of these Terms is found to
          be invalid or unenforceable by a court of competent jurisdiction, the
          remaining provisions shall continue in full force and effect. The
          invalid or unenforceable provision shall be deemed modified so that it
          is valid and enforceable to the maximum extent permitted by law.
        </p>
        <p className={styles.content}>
          14. <b>Entire Agreement:</b> These Terms constitute the entire
          agreement between you and PontoAI with respect to the services and
          supersede all prior or contemporaneous understandings and agreements,
          whether written or oral, with respect to the services.
        </p>
        <p className={styles.content}>
          By using PontoAI, you acknowledge that you have read, understood, and
          agree to be bound by these Terms. If you do not agree to these Terms,
          please refrain from using our services.
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={onCancel}>
          Cancel
        </button>
        <button className={styles.agreeButton} onClick={onAgree}>
          Agree
        </button>
      </div>
    </div>
  );
};

export default TermsOfService;
