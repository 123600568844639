import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Navbar";
import styles from "./SignUpPage.module.css";
import UserLogo from "../../assets/UserLogo.svg";
import EmailLogo from "../../assets/EmailLogo.svg";
import CompanyLogo from "../../assets/CompanyLogo.svg";
import UrlLogo from "../../assets/UrlLogo.svg";
import PasswordLogo from "../../assets/PasswordLogo.svg";
import ViewLogo from "../../assets/ViewLogo.svg";
import TermsOfService from "../TermsOfService"; // Import the new TermsOfService component

interface AxiosError {
  response?: {
    data: {
      message: string;
    };
    status: number;
  };
  isAxiosError: boolean;
}

const SignUpPage: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false); // State for terms acceptance
  const [showTerms, setShowTerms] = useState(false); // State to manage terms modal visibility

  const MIN_PASSWORD_LENGTH = 8;

  const navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCooldown > 0) {
      timer = setInterval(() => {
        setResendCooldown(resendCooldown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendCooldown]);

  const handleSignUpClick = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^.+\..+$/;

    if (
      !name ||
      !email ||
      !companyName ||
      !companyUrl ||
      !password ||
      !confirmPassword
    ) {
      setErrorMessage("All sections must be filled.");
      return;
    }
    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email address.");
      return;
    }
    if (!urlRegex.test(companyUrl)) {
      setErrorMessage("Invalid URL.");
      return;
    }
    if (password.length < MIN_PASSWORD_LENGTH) {
      setErrorMessage(
        `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`
      );
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    if (!isTermsAccepted) {
      setErrorMessage("You must accept the Terms of Service to proceed.");
      return;
    }

    setIsLoading(true); // Start loading

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const body = { name, email, companyName, companyUrl, password };
    const headers = { "Content-Type": "application/json" };

    try {
      const response = await axios.post(`${backendUrl}/auth/signup`, body, {
        headers,
      });
      console.log("Signup successful", response.data);
      setIsSignedUp(true);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError;
        const errorMessage = serverError.response
          ? serverError.response.data.message
          : "An error occurred during signup.";
        setErrorMessage(errorMessage);
      } else {
        console.error("Signup failed", error);
        setErrorMessage("An unexpected error occurred.");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleSignInClick = () => {
    navigate("/signin");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleResendEmail = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/resend`, {
        email,
      });
      setResendCooldown(60); // Start 60 second cooldown
    } catch (error) {
      console.error("Error resending the email", error);
      setErrorMessage("Failed to resend verification email.");
    }
  };

  const handleTermsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowTerms(true);
  };

  const handleTermsAgree = () => {
    setIsTermsAccepted(true);
    setShowTerms(false);
  };

  const handleTermsCancel = () => {
    setShowTerms(false);
  };

  return (
    <div className={styles.signInPage}>
      <Navbar />
      <div className={styles.mainContent}>
        {isSignedUp ? (
          <div className={styles.verificationContainer}>
            <h1 className={styles.verificationTitle}>
              Please verify your email
            </h1>
            <p className={styles.verificationText}>
              You're almost there. We sent an email to <strong>{email}</strong>.
              <br />
              <br />
              Check your inbox or spam folder.
            </p>
            <button
              className={styles.verificationButton}
              onClick={handleResendEmail}
              disabled={resendCooldown > 0}
            >
              {resendCooldown > 0
                ? `Wait ${resendCooldown}s to resend...`
                : "Resend Email"}
            </button>
            <div className={styles.additionalInfo}>
              Still can't find the email?{" "}
              <a
                href="/?scrollTo=contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
        ) : (
          <div className={styles.formContainer}>
            <h1 className={styles.title}>Create Account</h1>
            <form className={styles.form} onSubmit={handleSignUpClick}>
              <div className={styles.inputGroup}>
                <img src={UserLogo} alt="User" className={styles.userIcon} />
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Full Name"
                  required
                  className={styles.input}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className={styles.inputGroup}>
                <img src={EmailLogo} alt="Email" className={styles.userIcon} />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                  className={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className={styles.inputGroup}>
                <img
                  src={CompanyLogo}
                  alt="Company"
                  className={styles.companyIcon}
                />
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  placeholder="Company Name"
                  required
                  className={styles.input}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className={styles.inputGroup}>
                <img src={UrlLogo} alt="URL" className={styles.urlIcon} />
                <input
                  type="text"
                  id="company_url"
                  name="company_url"
                  placeholder="Company URL"
                  required
                  className={styles.input}
                  value={companyUrl}
                  onChange={(e) => setCompanyUrl(e.target.value)}
                />
              </div>
              <div className={styles.inputGroup}>
                <img
                  src={PasswordLogo}
                  alt="Password"
                  className={styles.passwordIcon}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                  className={styles.input}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  src={ViewLogo}
                  alt="View"
                  className={styles.viewIcon}
                  onClick={togglePasswordVisibility}
                />
              </div>
              <div className={styles.inputGroup}>
                <img
                  src={PasswordLogo}
                  alt="Password"
                  className={styles.passwordIcon}
                />
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  required
                  className={styles.input}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img
                  src={ViewLogo}
                  alt="View"
                  className={styles.viewIcon}
                  onClick={toggleConfirmPasswordVisibility}
                />
              </div>
              <div className={styles.termsContainer}>
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={isTermsAccepted}
                  onChange={(e) => setIsTermsAccepted(e.target.checked)}
                  className={styles.checkbox}
                />
                <label htmlFor="terms" className={styles.termsLabel}>
                  I agree to the{" "}
                  <span onClick={handleTermsClick} className={styles.termsLink}>
                    Terms of Service
                  </span>
                </label>
              </div>
              {errorMessage && (
                <div className={styles.errorText}>{errorMessage}</div>
              )}
              <button
                type="submit"
                className={styles.signInButton}
                disabled={isLoading}
              >
                {isLoading ? "Signing Up..." : "Sign Up"}
              </button>
            </form>
            <div className={styles.signUpContainer}>
              <span className={styles.signUpText}>
                Already have an account?
              </span>
              <button className={styles.linkButton} onClick={handleSignInClick}>
                Sign In
              </button>
            </div>
          </div>
        )}
      </div>
      {showTerms && (
        <>
          <div
            className={styles.overlayBackdrop}
            onClick={handleTermsCancel}
          ></div>
          <TermsOfService
            onAgree={handleTermsAgree}
            onCancel={handleTermsCancel}
          />
        </>
      )}
    </div>
  );
};

export default SignUpPage;
