import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import styles from "./SupportDetails.module.css"; 

const SupportDetails: React.FC = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [isLoadingContactInfo, setIsLoadingContactInfo] = useState(true);
  const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    const fetchContactInfo = async () => {
      try {
        setIsLoadingContactInfo(true);
        const response = await axios.get(
          `${backendUrl}/api/company-contact-info`,
          {
            headers,
          }
        );
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setUserEmail(response.data.email);
        setUserPhone(response.data.phone);
        setIsLoadingContactInfo(false);
      } catch (error) {
        console.error("Failed to fetch contact information", error);
        setIsLoadingContactInfo(false);
      }
    };

    fetchContactInfo();
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPhone(e.target.value);
  };

  const handleCancel = useCallback(() => {
    setUserEmail(email);
    setUserPhone(phone);
    setIsEditingContactInfo(false);
  }, [email, phone]);

  const saveContactInfo = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const updateResponse = await axios.post(
        `${backendUrl}/api/update-company-contact-info`,
        { email: userEmail, phone: userPhone },
        { headers }
      );
      console.log("Update response:", updateResponse.data);
      setEmail(userEmail);
      setPhone(userPhone);
      setIsEditingContactInfo(false);
    } catch (error) {
      console.error("Failed to update contact information", error);
    }
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        handleCancel();
      }
    },
    [handleCancel]
  );

  useEffect(() => {
    if (isEditingContactInfo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditingContactInfo, handleClickOutside]);

  return (
    <div className={styles.supportDetailsContainer}>
      <h3>Support Contact</h3>
      {isLoadingContactInfo ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.contactInputContainer} ref={containerRef}>
          <div className={styles.contactInputGroup}>
            <label>Email:</label>
            <input
              type="email"
              value={userEmail}
              onChange={handleEmailChange}
              onFocus={() => setIsEditingContactInfo(true)}
              className={styles.inputEmailField}
            />
            <label>Phone:</label>
            <input
              type="tel"
              value={userPhone}
              onChange={handlePhoneChange}
              onFocus={() => setIsEditingContactInfo(true)}
              className={styles.inputPhoneField}
            />
          </div>
          {isEditingContactInfo && (
            <div className={styles.buttonGroup}>
              <button onClick={handleCancel} className={styles.cancelButton}>
                Cancel
              </button>
              <button onClick={saveContactInfo} className={styles.saveButton}>
                Save
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SupportDetails;
