import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import styles from "./PricingPage.module.css";

interface Plan {
  name: string;
  price: number;
  messageLimit: number;
  order: number;
  priceId: string;
}

const PricingPage: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    axios
      .get(`${backendUrl}/billing/plans`)
      .then((response) => {
        const sortedPlans = response.data.sort(
          (a: Plan, b: Plan) => a.order - b.order
        );
        const filteredPlans = sortedPlans.filter(
          (plan: Plan) => plan.name !== "test"
        );
        setPlans(filteredPlans);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Error fetching plans:",
          error.message || "Network error"
        );
        setError("Failed to load pricing plans. Please try again later.");
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.pricingPageContainer}>
      <Navbar />
      <div className={styles.pricingContent}>
        <h1>Pricing</h1>
        {loading ? (
          <p>Loading plans...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <p>Our pricing plans are designed to fit your needs.</p>
            <div className={styles.plansContainer}>
              {plans.map((plan, index) => (
                <div key={index} className={styles.planCard}>
                  <h2 className={styles.planName}>{plan.name}</h2>
                  <ul className={styles.featuresList}>
                    <li className={styles.planDescription}>
                      {plan.name === "Free"
                        ? "Perfect to get started."
                        : plan.name === "Basic"
                        ? "Ideal for small teams."
                        : plan.name === "Pro"
                        ? "Best for growing businesses."
                        : "Custom solutions for large enterprises."}
                    </li>
                    {plan.name !== "Enterprise" ? (
                      <>
                        <li>Up to {plan.messageLimit} messages</li>
                        <li>
                          {plan.name === "Free"
                            ? "Basic support"
                            : plan.name === "Basic"
                            ? "Basic Analytics"
                            : "Advanced Analytics"}
                        </li>
                      </>
                    ) : (
                      <>
                        <p className={styles.featuresTitle}>Features:</p>
                        <li>Customized Solutions</li>
                      </>
                    )}
                    <li className={styles.planPrice}>
                      {plan.price === 0
                        ? "$0/month"
                        : plan.name === "Enterprise"
                        ? ""
                        : `$${plan.price}/month`}
                    </li>
                  </ul>
                  <button className={styles.planButton}>
                    {plan.name === "Enterprise"
                      ? "Contact Us"
                      : plan.name === "Free"
                      ? "Start for Free"
                      : "Subscribe"}
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PricingPage;
