import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import ChatsBar from "./ChatsBar";
import styles from "./ChatsTab.module.css";

const ChatsTab: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialLoad, setInitialLoad] = useState(true);

  const handleLatestConversationId = (id: string) => {
    // Only navigate automatically if it's the initial load and no conversationId is in the URL
    if (initialLoad && !location.pathname.includes("/dashboard/chats/")) {
      navigate(`/dashboard/chats/${id}`);
      setInitialLoad(false); // Set initialLoad to false after the first navigation
    }
  };

  useEffect(() => {
    // If the URL already contains a conversation ID, don't auto-navigate
    if (location.pathname.includes("/dashboard/chats/")) {
      setInitialLoad(false);
    }
  }, [location]);

  return (
    <div className={styles.chatsTab}>
      <ChatsBar onLatestConversationId={handleLatestConversationId} />
      <div className={styles.chatsAreaContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default ChatsTab;
