export const splitMessage = (message: string): string[] => {
  const initialMaxChars = 150;
  let maxChars = initialMaxChars;

  // Trim only leading and trailing whitespace, but preserve internal \n
  let processedMessage = processHyperLink(message.trim());

  // Early return if bulleted or numbered list is detected or if there are more than two consecutive newlines
  const listPattern = /(^|\n)\s*[-*\d](\.|\s)/;
  const multipleNewlinesPattern = /\n{3,}/;
  if (listPattern.test(processedMessage) || multipleNewlinesPattern.test(processedMessage)) {
    return [processedMessage];
  }

  
  // List of common abbreviations
  const abbreviations = [
    'Prof.', 'Dr.', 'Mr.', 'Ms.', 'Mrs.', 'Sr.', 'Jr.', 'Rev.', 'Fr.', 'St.',
    'B.A.', 'M.A.', 'Ph.D.', 'M.D.', 'D.D.S.', 'a.m.', 'p.m.',
    'etc.', 'i.e.', 'e.g.', 'vs.', 'Co.', 'Inc.', 'Ltd.', 'No.',
  ];

  // Replace periods in abbreviations with a placeholder
  const placeholder = '<<PERIOD>>';
  abbreviations.forEach(abbr => {
    processedMessage = processedMessage.replace(new RegExp(abbr, 'g'), abbr.replace(/\./g, placeholder));
  });

  // Split based on punctuation followed by a space
  let sentences: string[] = processedMessage
    .replace(/\n{2,}/g, "\n") // Replace multiple newlines with a single newline
    .split(/(?<=[.!?])\s+/) // Split based on punctuation followed by a space
    .map(sentence => sentence.replace(new RegExp(placeholder, 'g'), '.')) // Replace placeholder back to period
    .filter(Boolean);
  
  let messages: string[] = [];
  let currentMessage: string[] = [];

  const stripHtmlTags = (str: string): string => str.replace(/<[^>]*>/g, "");

  sentences.forEach((sentence: string, index: number) => {
    const strippedCurrentMessage = stripHtmlTags(currentMessage.join(" "));
    const strippedSentence = stripHtmlTags(sentence);

    if (
      strippedCurrentMessage.length + strippedSentence.length + 1 >
      maxChars
    ) {
      if (currentMessage.length > 0) {
        messages.push(currentMessage.join(" ").trim());
      }
      currentMessage = [sentence];
    } else {
      currentMessage.push(sentence);
    }
  });

  if (currentMessage.length > 0) {
    messages.push(currentMessage.join(" ").trim());
  }

  if (messages.length >= 3) {
    maxChars = 200;
    const regroupedMessages: string[] = [];
    currentMessage = [];

    messages.forEach((msg, index) => {
      const strippedCurrentMessage = stripHtmlTags(currentMessage.join(" "));
      const strippedMsg = stripHtmlTags(msg);

      if (strippedCurrentMessage.length + strippedMsg.length + 1 > maxChars) {
        console.log(
          `Message ${
            index + 1
          } causes overflow. Pushing current message to regroupedMessages.`
        );
        if (currentMessage.length > 0) {
          regroupedMessages.push(currentMessage.join(" ").trim());
        }
        currentMessage = [msg];
      } else {
        currentMessage.push(msg);
      }
    });

    if (currentMessage.length > 0) {
      regroupedMessages.push(currentMessage.join(" ").trim());
    }

    messages = regroupedMessages;
  }

  if (messages.length > 1) {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.startsWith("<a ") && lastMessage.endsWith("</a>")) {
      messages[messages.length - 2] += " " + lastMessage;
      messages.pop();
    }
  }

  let i = 1;
  while (i < messages.length) {
      if (messages[i].split(' ').length < 7) {
          messages[i - 1] += ' ' + messages[i];
          messages.splice(i, 1);
      } else {
          i += 1;
      }
  }

  return messages;
};

export const processHyperLink = (message: string): string => {
  const format1Regex = /\[([^\]]+)\]\(<url>(https?:\/\/[^\s<>]+)<\/url>\)/g;
  const format2Regex = /\(<url>(https?:\/\/[^\s<>]+)<\/url>\)/g;
  const format3Regex =
    /\[([^\]]+)\]\((https?:\/\/[^\s<>]+|[^\s<>]+\.(com|net|org|io)[^\s<>]*)\)/g;

  message = message.replace(
    format1Regex,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
  );

  message = message.replace(
    format2Regex,
    '<a href="$1" target="_blank" rel="noopener noreferrer">link</a>'
  );

  message = message.replace(
    format3Regex,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
  );

  return message;
};
