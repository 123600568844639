import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Billing.module.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

interface Plan {
  name: string;
  price: number;
  messageLimit: number;
  order: number;
  priceId: string;
}

export interface BillingEntry {
  date: string;
  price: number;
}

const showTestPlan = false;

const Billing: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [currentUserPlan, setCurrentUserPlan] = useState<string | null>(null);
  const [renewDate, setRenewDate] = useState<string | null>(null);
  const [billingHistory, setBillingHistory] = useState<BillingEntry[]>([]);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState<boolean>(true);

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const userEmail = localStorage.getItem("userEmail");

    axios
      .get(`${backendUrl}/billing/plans`)
      .then((response) => {
        const sortedPlans = response.data.sort(
          (a: Plan, b: Plan) => a.order - b.order
        );
        if (!showTestPlan) {
          const filteredPlans = sortedPlans.filter(
            (plan: Plan) => plan.name !== "test"
          );
          setPlans(filteredPlans);
        } else {
          setPlans(sortedPlans);
        }
      })
      .catch((error) => {
        console.error(
          "Error fetching plans:",
          error.message || "Network error"
        );
      });

    if (userEmail) {
      axios
        .get(`${backendUrl}/billing/current_plan`, {
          headers: { "user-email": userEmail },
        })
        .then((response) => {
          const { plan, renewDate, autoRenewal } = response.data;
          setCurrentUserPlan(plan.name);
          setRenewDate(new Date(renewDate).toLocaleDateString("en-US"));
          setAutoRenewal(autoRenewal);
        })
        .catch((error) => {
          console.error(
            "Error fetching current user's plan:",
            error.message || "Network error"
          );
        });

      axios
        .get(`${backendUrl}/billing/billing_history`, {
          headers: { "user-email": userEmail },
        })
        .then((response) => {
          setBillingHistory(response.data.billingHistory);
        })
        .catch((error) => {
          console.error(
            "Error fetching billing history:",
            error.message || "Network error"
          );
        });
    }
  }, []);

  const updateCurrentPlan = (newPlan: string, newRenewDate: string) => {
    setCurrentUserPlan(newPlan);
    setRenewDate(newRenewDate);
  };

  const handleSelect = (plan: Plan) => {
    if (currentUserPlan === plan.name && isCheckoutOpen) {
      setIsCheckoutOpen(false);
    } else {
      setSelectedPlan(plan);
      setIsCheckoutOpen(true);
    }
  };

  const closeCheckoutForm = () => {
    setIsCheckoutOpen(false);
    setSelectedPlan(null);
  };

  const handleAutoRenewalChange = () => {
    setAutoRenewal((prev) => !prev);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const userEmail = localStorage.getItem("userEmail");

    if (userEmail) {
      axios
        .post(
          `${backendUrl}/billing/update_auto_renewal`,
          { autoRenewal: !autoRenewal },
          { headers: { "user-email": userEmail } }
        )
        .catch((error) => {
          console.error(
            "Error updating auto-renewal status:",
            error.message || "Network error"
          );
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.selectedPlan}>
          Current Plan: {currentUserPlan ? currentUserPlan : "None"}
        </span>
        <div className={styles.autoRenewal}>
          <span>Auto-renewal:</span>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={autoRenewal}
              onChange={handleAutoRenewalChange}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
        <span className={styles.renewDate}>
          Renew Date: {renewDate ? renewDate : "N/A"}
        </span>
      </div>
      <div className={styles.planContainer}>
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`${styles.plan} ${
              currentUserPlan === plan.name ? styles.currentPlan : ""
            }`}
          >
            <h3>{plan.name}</h3>
            <h4>
              {plan.name === "Enterprise" ? "Contact Us" : `$${plan.price}/mo`}
            </h4>
            <ul>
              <li>
                {plan.name === "Enterprise"
                  ? "5000+ messages"
                  : `${plan.messageLimit} messages`}
              </li>
            </ul>
            {plan.name === "Enterprise" ? (
              <button className={styles.contactSalesButton}>
                Contact Sales
              </button>
            ) : (
              <button
                className={
                  currentUserPlan === plan.name ? styles.selectedButton : ""
                }
                onClick={() => handleSelect(plan)}
              >
                {currentUserPlan === plan.name ? "Selected" : "Select"}
              </button>
            )}
          </div>
        ))}
      </div>
      {isCheckoutOpen && selectedPlan && selectedPlan.name !== "Enterprise" && (
        <div className={styles.stripeCheckoutContainer}>
          <Elements stripe={stripePromise}>
            <StripeCheckoutForm
              plan={selectedPlan}
              onClose={closeCheckoutForm}
              updatePlan={updateCurrentPlan}
              autoRenewal={autoRenewal}
              handleAutoRenewalChange={handleAutoRenewalChange}
            />
          </Elements>
        </div>
      )}
      <div className={styles.billingContactContainer}>
        <div className={styles.billingHistory}>
          <h3>Billing History</h3>
          <div className={styles.listContainer}>
            {billingHistory.length > 0 ? (
              <ul>
                {billingHistory.map((entry, index) => (
                  <li key={index}>
                    <span>{new Date(entry.date).toLocaleDateString()}</span> -{" "}
                    <span>${entry.price.toFixed(2)}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No billing history available.</p>
            )}
          </div>
        </div>

        <div className={styles.contactForm}>
          <h3>Contact Us</h3>
          <form>
            <div className={styles.formGroup}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                rows={4}
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Billing;
