import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './AdminNavBar.module.css';
import PontoAI from "../../assets/PontoAISmallerTxt.svg";

const AdminNavBar: React.FC = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Conversations");
  const [userName, setUserName] = useState<string | null>(localStorage.getItem("userName"));
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const hideTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleStorageChange = () => {
      setUserName(localStorage.getItem("userName"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    navigate("/");
  };

  const showDropdown = () => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
      hideTimeout.current = null;
    }
    setIsDropdownVisible(true);
  };

  const hideDropdown = () => {
    hideTimeout.current = setTimeout(() => {
      setIsDropdownVisible(false);
    }, 200);
  };

  const getFirstLetter = (name: string | null) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  const navigateToTab = (tab: string) => {
    setSelectedTab(tab);
    switch (tab) {
      case "Conversations":
        navigate("/admin/conversations");
        break;
      case "User Billing":
        navigate("/admin/billing");
        break;
      case "UserInfo":
        navigate("/admin/userInfo");
        break;
    }
  };

  return (
    <div className={styles.adminNavBar}>
      <img
        src={PontoAI}
        alt="PontoAI Logo"
        className={styles.pontoAILogo}
      />
      <button
        className={`${styles.adminTab} ${selectedTab === "Conversations" ? styles.adminSelected : ""}`}
        onClick={() => navigateToTab("Conversations")}
      >
        Conversations
      </button>
      <button
        className={`${styles.adminTab} ${selectedTab === "User Billing" ? styles.adminSelected : ""}`}
        onClick={() => navigateToTab("User Billing")}
      >
        User Billing
      </button>
      <button
        className={`${styles.adminTab} ${selectedTab === "UserInfo" ? styles.adminSelected : ""}`}
        onClick={() => navigateToTab("UserInfo")}
      >
        User Info
      </button>
      <div
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        className={styles.authButtons}
      >
        <div
          className={styles.navProfile}
          onClick={() => navigate("/admin")}
        >
          {getFirstLetter(userName)}
        </div>
        {isDropdownVisible && (
          <div
            className={styles.dropdownMenu}
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
          >
            <a href="/admin" className={styles.dropdownItem}>
              Dashboard
            </a>
            <a href="/account" className={styles.dropdownItem}>
              Account
            </a>
            <div className={styles.dropdownDivider}></div>
            <button
              onClick={handleLogout}
              className={`${styles.dropdownItem} ${styles.dropdownButton}`}
            >
              Sign Out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminNavBar;
