import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import SignInPage from "./components/AuthPages/SignInPage";
import SignUpPage from "./components/AuthPages/SignUpPage";
import Dashboard from "./components/DashboardPages/Dashboard";
import EmailVerificationPage from "./components/AuthPages/EmailVerificationPage";
import PrivateRoute from "./components/PrivateRoute";
import PasswordResetPage from "./components/AuthPages/PasswordResetPage";
import PasswordUpdatePage from "./components/AuthPages/PasswordUpdatePage";
import DemoPage from "./components/DemoPages/DemoPage";
import DevPage from "./components/DemoPages/DevPage";
import SetupTab from "./components/DashboardPages/SetupTab/SetupTab";
import ChatsTab from "./components/DashboardPages/ChatsTab/ChatsTab";
import ChatsArea from "./components/DashboardPages/ChatsTab/ChatsArea";
import UsageTab from "./components/DashboardPages/UsageTab/UsageTab";
import BillingTab from "./components/DashboardPages/Billing";
import AdminConversationsPage from "./components/AdminPages/AdminConversationsPage/AdminConversationsPage";
import AdminConversations from "./components/AdminPages/AdminConversationsPage/AdminConversations";
import AdminChat from "./components/AdminPages/AdminConversationsPage/AdminChat";
import AdminUserInfoPage from "./components/AdminPages/AdminUserInfoPage/AdminUserInfoPage";
import PricingPage from "./components/PricingPage";
// import TermsOfService from "./components/TermsOfService";
import "./App.css";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* <Route path="/terms-of-service" element={<TermsOfService />} /> */}
          <Route path="/password" element={<PasswordResetPage />} />
          <Route path="/update-password" element={<PasswordUpdatePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/demo/:nameAlias" element={<DemoPage />} />
          <Route path="/dev/:nameAlias" element={<DevPage />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={Dashboard} />}
          >
            <Route path="chats/*" element={<ChatsTab />}>
              <Route path=":conversationId" element={<ChatsArea />} />
            </Route>
            <Route path="setup" element={<SetupTab />} />
            <Route path="usage" element={<UsageTab />} />
            <Route path="billing" element={<BillingTab />} />
            <Route index element={<SetupTab />} />
          </Route>

          <Route
            path="/admin"
            element={<Navigate to="/admin/conversations" />}
          />
          <Route
            path="/admin/conversations"
            element={<PrivateRoute element={AdminConversationsPage} />}
          >
            <Route path=":companyId" element={<AdminConversations />}>
              <Route path=":conversationId" element={<AdminChat />} />
            </Route>
          </Route>
          <Route
            path="/admin/billing"
            element={<PrivateRoute element={BillingTab} />}
          />
          <Route
            path="/admin/userInfo"
            element={<PrivateRoute element={AdminUserInfoPage} />}
          />
          <Route
            path="/email-verification"
            element={<EmailVerificationPage />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
