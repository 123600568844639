import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import styles from "./AdminChat.module.css";
import AdminChatNavBar from "./AdminChatNavBar";
import AdminChatDisplay from "./AdminChatDisplay";
import AdminChatSend from "./AdminChatSend";

import { Conversation } from "../../../types/types";

const AdminChat: React.FC = () => {
  const { companyId, conversationId } = useParams<{
    companyId: string;
    conversationId: string;
  }>();
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [selectedRevision, setSelectedRevision] = useState<number>(() => {
    const storedRevision = sessionStorage.getItem(
      `selectedRevision_${conversationId}`
    );
    return storedRevision ? parseInt(storedRevision, 10) : -1;
  });

  // Modify this useEffect
  useEffect(() => {
    const storedRevision = sessionStorage.getItem(
      `selectedRevision_${conversationId}`
    );
    if (storedRevision) {
      setSelectedRevision(parseInt(storedRevision, 10));
    } else {
      setSelectedRevision(-1);
      sessionStorage.removeItem(`selectedRevision_${conversationId}`);
    }
  }, [conversationId, companyId]);

  const fetchConversation = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/adminConvo/get-conversations/${companyId}/${conversationId}`
      );
      if (!response.ok) {
        throw new Error("Error fetching conversation");
      }
      const data = await response.json();
      setConversation(data);
    } catch (error) {
      console.error("Failed to fetch conversation:", error);
    }
  }, [companyId, conversationId]);

  useEffect(() => {
    if (companyId && conversationId) {
      fetchConversation();
    }
  }, [companyId, conversationId, fetchConversation]);

  useEffect(() => {
    if (conversationId) {
      sessionStorage.setItem(
        `selectedRevision_${conversationId}`,
        selectedRevision.toString()
      );
    }
  }, [selectedRevision, conversationId]);

  if (!conversation) {
    return <div>Loading...</div>;
  }

  if (!conversationId) {
    return <div>Error: conversationId is undefined</div>;
  }

  const deleteRevision = async (revisionIndex: number) => {
    if (!conversation) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/adminConvo/delete-revision/${companyId}/${conversation._id}/${revisionIndex}`,
        { method: "DELETE" }
      );
      if (!response.ok) {
        throw new Error("Failed to delete revision");
      }

      // Remove the revision locally
      const updatedRevisions = conversation.revisions.filter(
        (_, index) => index !== revisionIndex
      );
      setConversation({ ...conversation, revisions: updatedRevisions });
      setSelectedRevision(-1); // Reset selected revision
    } catch (error) {
      console.error("Failed to delete revision:", error);
    }
  };

  const messagesToDisplay =
    selectedRevision === -1
      ? conversation.messages
      : conversation.revisions[selectedRevision]?.messages || [];

  return (
    <div className={styles.chatContainer}>
      <div className={styles.adminChatNavBarComponent}>
        <AdminChatNavBar
          conversation={conversation}
          setConversation={setConversation}
          selectedRevision={selectedRevision}
          setSelectedRevision={setSelectedRevision}
        />
      </div>
      <div className={styles.adminChatDisplayContainer}>
        <AdminChatDisplay
          messages={messagesToDisplay}
          isRevision={selectedRevision !== -1}
          selectedRevision={selectedRevision}
          conversationId={conversationId}
          onDeleteRevision={deleteRevision}
          onMessagesUpdate={fetchConversation}
        />
      </div>
      <div className={styles.adminChatSendContainer}>
        <AdminChatSend conversationId={conversationId} />
      </div>
    </div>
  );
};

export default AdminChat;
