import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./SetupScripts.module.css";
import CopyLogo from "../../../assets/CopyLogo_white.svg";

interface SetupScriptsProps {
  chatbotInitialized: boolean | null;
  chatbotScraped: boolean | null;
  highlightChatbotMessage: () => void;
}

const SetupScripts: React.FC<SetupScriptsProps> = ({
  chatbotInitialized,
  chatbotScraped,
  highlightChatbotMessage,
}) => {
  const [script, setScript] = useState<string | null>(null);
  const [reactScript, setReactScript] = useState<string | null>(null);
  const [showScriptCopied, setShowScriptCopied] = useState(false);
  const [showReactScriptCopied, setShowReactScriptCopied] = useState(false);
  const [isChatbotEnabled, setIsChatbotEnabled] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    const fetchApiKeyAndChatbotStatus = async () => {
      try {
        const [apiKeyResponse, chatbotStatusResponse] = await Promise.all([
          axios.get(`${backendUrl}/api/api-key`, { headers }),
          axios.get(`${backendUrl}/api/chatbot-status`, { headers }),
        ]);

        if (apiKeyResponse.data.apiKey) {
          setScript(
            `<script src="https://static.pontoai.com/ChatUI.js" async id="chat-script" api_key="${apiKeyResponse.data.apiKey}"></script>`
          );
          const scriptContent = `
React.useEffect(() => {
  const script = document.createElement('script');
  script.src = "https://static.pontoai.com/ChatUI.js";
  script.async = true;
  script.id = "chat-script";
  script.setAttribute("api_key", "${apiKeyResponse.data.apiKey}");
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
}, []);`.trim();
          setReactScript(scriptContent);
        }

        setIsChatbotEnabled(chatbotStatusResponse.data.chatbotActive);
      } catch (error) {
        console.error("Failed to fetch data from backend", error);
        setScript(null);
      }
    };

    fetchApiKeyAndChatbotStatus();
  }, []);

  const copyToClipboard = (id: string) => {
    const copyText = document.getElementById(id) as HTMLTextAreaElement | null;
    if (copyText) {
      navigator.clipboard
        .writeText(copyText.value)
        .then(() => {
          if (id === "script") {
            setShowScriptCopied(true);
            setTimeout(() => setShowScriptCopied(false), 2000);
          } else if (id === "react-script") {
            setShowReactScriptCopied(true);
            setTimeout(() => setShowReactScriptCopied(false), 2000);
          }
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  const handleToggle = async () => {
    if (chatbotInitialized && chatbotScraped) {
      const newChatbotStatus = !isChatbotEnabled;
      setIsChatbotEnabled(newChatbotStatus);

      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        await axios.post(
          `${backendUrl}/api/set-chatbot-status`,
          { chatbotActive: newChatbotStatus },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } catch (error) {
        setIsChatbotEnabled(!newChatbotStatus);
      }
    } else {
      highlightChatbotMessage();
    }
  };

  if (isChatbotEnabled === null) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.setupScriptsContainer}>
      <div className={styles.scriptSection}>
        <div className={styles.toggleSection}>
          <h3>Script</h3>
          <label
            className={`${styles.toggleLabel} ${
              isChatbotEnabled ? styles.enabled : styles.disabled
            }`}
          >
            Chatbot {isChatbotEnabled ? "Enabled" : "Disabled"}
            <input
              type="checkbox"
              checked={isChatbotEnabled}
              onChange={handleToggle}
              className={styles.toggleInput}
              // disabled={!chatbotInitialized || !chatbotScraped}
            />
            <span></span>
          </label>
        </div>
        {script ? (
          <div className={styles.scriptContainer}>
            <textarea
              id="script"
              value={script}
              readOnly
              className={styles.scriptTextarea}
            />
            <button
              onClick={() => copyToClipboard("script")}
              className={styles.copyButton}
            >
              <img src={CopyLogo} alt="Copy Script" />
            </button>
          </div>
        ) : (
          <p>Loading script...</p>
        )}
        {showScriptCopied && (
          <span className={styles.copiedMessage}>Copied to clipboard!</span>
        )}
      </div>
      <div className={styles.scriptSection}>
        <h3>React Script</h3>
        {reactScript ? (
          <div className={styles.scriptContainer}>
            <textarea
              id="react-script"
              value={reactScript}
              readOnly
              className={styles.scriptTextarea}
            />
            <button
              onClick={() => copyToClipboard("react-script")}
              className={styles.copyButton}
            >
              <img src={CopyLogo} alt="Copy React Script" />
            </button>
          </div>
        ) : (
          <p>Loading script...</p>
        )}
        {showReactScriptCopied && (
          <span className={styles.copiedMessage}>Copied to clipboard!</span>
        )}
      </div>
    </div>
  );
};

export default SetupScripts;
