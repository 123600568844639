import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import styles from "./UrlDetails.module.css";

interface URL {
  _id: string;
  topic: string;
  url: string;
  description: string;
  createdAt: string;
}

const UrlDetails: React.FC = () => {
  const [urls, setUrls] = useState<URL[]>([]);
  const [newUrl, setNewUrl] = useState({ topic: "", url: "", description: "" });
  const [editingUrl, setEditingUrl] = useState<URL | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formError, setFormError] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
    []
  );

  const fetchCompanyReferenceUrls = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${backendUrl}/api/company-reference-urls`,
        { headers }
      );
      setUrls(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch reference URLs", error);
      setIsLoading(false);
    }
  }, [backendUrl, headers]);

  useEffect(() => {
    fetchCompanyReferenceUrls();
  }, [fetchCompanyReferenceUrls]);

  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const addUrl = async () => {
    if (!newUrl.topic || !newUrl.url) {
      setFormError("Topic and URL are required");
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      errorTimeoutRef.current = setTimeout(() => {
        setFormError("");
      }, 2000);
      return;
    }
    setFormError("");
    try {
      const response = await axios.post(
        `${backendUrl}/api/add-reference-url`,
        newUrl,
        { headers }
      );
      setUrls([response.data.newUrl, ...urls]);
      setNewUrl({ topic: "", url: "", description: "" });
      setIsFormVisible(false);
    } catch (error) {
      console.error("Failed to add reference URL", error);
    }
  };

  const removeUrl = async (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this URL?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`${backendUrl}/api/delete-reference-url/${id}`, {
        headers,
      });
      setUrls(urls.filter((url) => url._id !== id));
    } catch (error) {
      console.error("Failed to delete reference URL", error);
    }
  };

  const saveEdit = async () => {
    if (editingUrl) {
      try {
        const response = await axios.put(
          `${backendUrl}/api/update-reference-url/${editingUrl._id}`,
          editingUrl,
          { headers }
        );
        setUrls(
          urls.map((url) =>
            url._id === editingUrl._id ? response.data.updatedUrl : url
          )
        );
        setEditingUrl(null);
      } catch (error) {
        console.error("Failed to update reference URL", error);
      }
    }
  };

  const handleEdit = (url: URL) => {
    setEditingUrl(url);
  };

  const handleCancelEdit = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setEditingUrl(null);
  };

  const editRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editRef.current && !editRef.current.contains(event.target as Node)) {
        setEditingUrl(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editRef]);

  return (
    <div className={styles.urlDetails}>
      <h3>Relevant URLs</h3>
      <div className={styles.urlFormHeader}>
        <span>Topic</span>
        <span>URL</span>
        <span>Description</span>
        <button
          className={styles.plusButton}
          onClick={() => setIsFormVisible(!isFormVisible)}
        >
          +
        </button>
      </div>
      {isFormVisible && (
        <div className={styles.urlForm}>
          <input
            type="text"
            placeholder="Topic"
            value={newUrl.topic}
            onChange={(e) => setNewUrl({ ...newUrl, topic: e.target.value })}
          />
          <input
            type="text"
            placeholder="URL"
            value={newUrl.url}
            onChange={(e) => setNewUrl({ ...newUrl, url: e.target.value })}
          />
          <input
            type="text"
            placeholder="Description"
            value={newUrl.description}
            onChange={(e) =>
              setNewUrl({ ...newUrl, description: e.target.value })
            }
          />
          <div className={styles.buttons}>
            <button className={styles.addButton} onClick={addUrl}>
              Add
            </button>
            <button
              className={styles.cancelButton}
              onClick={() => setIsFormVisible(false)}
            >
              Cancel
            </button>
          </div>
          {formError && <div className={styles.error}>{formError}</div>}
        </div>
      )}

      <div className={styles.urlList}>
        {isLoading ? (
          <div className={styles.spinner}></div>
        ) : (
          urls.map((url) => (
            <div
              key={url._id}
              className={styles.urlItem}
              ref={editingUrl && editingUrl._id === url._id ? editRef : null}
            >
              {editingUrl && editingUrl._id === url._id ? (
                <>
                  <input
                    type="text"
                    value={editingUrl.topic}
                    onChange={(e) =>
                      setEditingUrl({ ...editingUrl, topic: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    value={editingUrl.url}
                    onChange={(e) =>
                      setEditingUrl({ ...editingUrl, url: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    value={editingUrl.description}
                    onChange={(e) =>
                      setEditingUrl({
                        ...editingUrl,
                        description: e.target.value,
                      })
                    }
                  />
                  <button className={styles.saveButton} onClick={saveEdit}>
                    Save
                  </button>
                  <button
                    className={styles.cancelButton}
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <div>{url.topic}</div>
                  <a
                    href={url.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.urlLink}
                  >
                    {url.url}
                  </a>
                  <div>{url.description}</div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(url);
                    }}
                    className={styles.editButton}
                  >
                    &#x22EE;
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeUrl(url._id);
                    }}
                    className={styles.deleteButton}
                  >
                    &#x2716;
                  </button>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UrlDetails;
