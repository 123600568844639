import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import styles from "./IntroQuestions.module.css";

interface Question {
  _id: string;
  question: string;
  createdAt: string;
}

const IntroQuestions: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [newQuestion, setNewQuestion] = useState({ question: "" });
  const [formError, setFormError] = useState("");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
    []
  );


  const generateQuestion = async () => {
    setIsGenerating(true);
    try {
      const response = await fetch(`${backendUrl}/api/generate-questions`, {
        method: "POST",
        headers,
      });

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let total = "";

      while (true) {
        const { done, value } = await reader?.read()!;
        if (done) break;

        const decodedValue = decoder.decode(value);
        total += decodedValue;
        setNewQuestion({ question: total });
      }
    } catch (error) {
      console.error("Failed to generate question", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const fetchIntroQuestions = useCallback(async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/company-intro-questions`,
        {
          headers,
        }
      );
      if (Array.isArray(response.data)) {
        const sortedQuestions = response.data.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setQuestions(sortedQuestions);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch intro questions", error);
    }
  }, [backendUrl, headers]);

  useEffect(() => {
    fetchIntroQuestions();
  }, [fetchIntroQuestions]);

  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const addQuestion = async () => {
    if (!newQuestion.question) {
      setFormError("Question is required");
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      errorTimeoutRef.current = setTimeout(() => {
        setFormError("");
      }, 2000);
      return;
    }
    setFormError("");
    try {
      await axios.post(`${backendUrl}/api/add-intro-question`, newQuestion, {
        headers,
      });

      await fetchIntroQuestions();
      setNewQuestion({ question: "" });
    } catch (error) {
      console.error("Failed to add intro question", error);
    }
  };

  const saveEdit = async () => {
    if (editingQuestion) {
      try {
        const response = await axios.put(
          `${backendUrl}/api/update-intro-question/${editingQuestion._id}`,
          { question: editingQuestion.question },
          { headers }
        );
        setQuestions(
          questions.map((q) =>
            q._id === editingQuestion._id ? response.data.updatedQuestion : q
          )
        );
        setEditingQuestion(null);
      } catch (error) {
        console.error("Failed to update intro question", error);
      }
    }
  };

  const handleEdit = (question: Question) => {
    setEditingQuestion(question);
  };

  const handleCancelEdit = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setEditingQuestion(null);
  };

  const removeQuestion = async (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (!confirmDelete) {
      return;
    }

    const index = questions.findIndex((q) => q._id === id);
    if (index === -1) {
      console.error("Question not found");
      return;
    }

    try {
      await axios.delete(`${backendUrl}/api/delete-intro-question/${index}`, {
        headers,
      });
      setQuestions(questions.filter((q) => q._id !== id));
    } catch (error) {
      console.error("Failed to delete intro question", error);
    }
  };

  const editRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editRef.current && !editRef.current.contains(event.target as Node)) {
        setEditingQuestion(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editRef]);

  return (
    <div className={styles.introQuestions}>
      <h3>Example Questions</h3>
      <div className={styles.questionForm}>
        <input
          type="text"
          placeholder="Enter a common visitor question, e.g., 'What are your business hours?'"
          value={newQuestion.question}
          onChange={(e) => setNewQuestion({ question: e.target.value })}
          className={styles.questionInput}
          disabled={isGenerating}
        />
        <button className={styles.saveButton} onClick={addQuestion} disabled={isGenerating}>
          Save
        </button>
        <button className={styles.generateButton} onClick={generateQuestion} disabled={isGenerating}>
          {isGenerating ? "Generating..." : "Generate"}
        </button>
        {formError && <div className={styles.error}>{formError}</div>}
      </div>

      <div className={styles.questionList}>
        {questions.map((q) => (
          <div
            key={q._id}
            className={styles.questionItem}
            ref={
              editingQuestion && editingQuestion._id === q._id ? editRef : null
            }
          >
            {editingQuestion && editingQuestion._id === q._id ? (
              <>
                <input
                  type="text"
                  value={editingQuestion.question}
                  onChange={(e) =>
                    setEditingQuestion({
                      ...editingQuestion,
                      question: e.target.value,
                    })
                  }
                />
                <button className={styles.saveButton} onClick={saveEdit}>
                  Save
                </button>
                <button
                  className={styles.cancelButton}
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <div>{q.question ? q.question : "No question text"}</div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(q);
                  }}
                  className={styles.editButton}
                >
                  &#x22EE;
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeQuestion(q._id);
                  }}
                  className={styles.deleteButton}
                >
                  &#x2716;
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntroQuestions;
