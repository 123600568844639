import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./AdminConversations.module.css";

interface Revision {
  _id: string;
  modifiedAt: number;
}

interface Message {
  _id: string;
  message: string;
  timestamp: number;
  sender: string;
  type: string;
}

interface Conversation {
  _id: string;
  company: string;
  endTimestamp: number;
  insertTimestamp: number;
  messages: Message[];
  revisions: Revision[];
}

const AdminConversations: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(() => localStorage.getItem("selectedConversationId"));

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/adminConvo/get-conversations/${companyId}`
        );
        if (!response.ok) {
          throw new Error("Error fetching conversations");
        }
        const data = await response.json();
        setConversations(data);
      } catch (error) {
        console.error("Failed to fetch conversations:", error);
      }
    };

    if (companyId) {
      fetchConversations();
    }
  }, [companyId]);

  const getLastMessage = (
    messages: Message[]
  ): { text: string; date: string } => {
    if (messages.length === 0) {
      return { text: "No messages", date: "" };
    }
    const lastMessage = messages[messages.length - 1];
    const date = new Date(lastMessage.timestamp * 1000).toLocaleDateString(
      undefined,
      { month: "numeric", day: "numeric" }
    );
    return { text: lastMessage.message, date };
  };

  const handleConversationClick = (conversationId: string) => {
    setSelectedConversationId(conversationId);
    localStorage.setItem("selectedConversationId", conversationId);
    navigate(`/admin/conversations/${companyId}/${conversationId}`);
  };

  return (
    <div className={styles.conversationsContainer}>
      <ul className={styles.conversationsList}>
        {conversations.map((conversation) => {
          const { text, date } = getLastMessage(conversation.messages);
          const hasRevisions = conversation.revisions.length > 0;
          return (
            <li
              key={conversation._id}
              className={`${styles.conversationItem} ${
                selectedConversationId === conversation._id
                  ? styles.selected
                  : ""
              }`}
              onClick={() => handleConversationClick(conversation._id)}
            >
              <span className={styles.messageText}>
                {hasRevisions && (
                  <span className={styles.revisionPrefix}>RE - </span>
                )}
                {text}
              </span>
              <span className={styles.messageDate}>{date}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AdminConversations;
