import React, { useState } from "react";
import styles from "./AdminChatNavBar.module.css";

interface UrlMessage {
  topic: string;
  url: string;
  description: string;
  _id: string;
  createdAt: number;
  updatedAt: number;
  positive: boolean;
}

interface Message {
  _id: string;
  message: string | UrlMessage;
  timestamp: number;
  sender: string;
  type: string;
  reworded_message?: string;
  positive: boolean;
}

interface Revision {
  labeler: string;
  notes: string;
  messages: Message[]; // Adjust according to your message schema
  createdAt: Date;
}

interface Conversation {
  _id: string;
  company: string;
  endTimestamp: number;
  insertTimestamp: number;
  messages: Message[];
  revisions: Revision[];
}

interface Props {
  conversation: Conversation;
  setConversation: React.Dispatch<React.SetStateAction<Conversation | null>>;
  selectedRevision: number;
  setSelectedRevision: (index: number) => void;
}

const AdminChatNavBar: React.FC<Props> = ({
  conversation,
  setConversation,
  selectedRevision,
  setSelectedRevision,
}) => {
  const [labeler, setLabeler] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("Save");

  const handleSelectRevision = (index: number) => {
    setSelectedRevision(index);
    if (index !== -1) {
      setLabeler(conversation.revisions[index].labeler);
      setNotes(conversation.revisions[index].notes);
    } else {
      setLabeler("");
      setNotes("");
    }
  };

  const handleCreateNewRevision = async () => {
    const userEmail = localStorage.getItem("userEmail");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/adminConvo/create-revision/${conversation.company}/${conversation._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            labeler: userEmail || "",
            notes: "",
            baseRevisionId: selectedRevision,
          }), // Use userEmail or default value
        }
      );
      if (!response.ok) {
        throw new Error("Error creating new revision");
      }
      const data = await response.json();
      setConversation((prev) => {
        if (prev) {
          return { ...prev, revisions: [...prev.revisions, data.revision] };
        }
        return prev;
      });
    } catch (error) {
      console.error("Failed to create new revision:", error);
    }
  };

  const handleSaveRevision = async () => {
    if (selectedRevision === -1) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/adminConvo/update-revision/${conversation.company}/${conversation._id}/${selectedRevision}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            labeler,
            notes,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error updating revision");
      }
      setConversation((prev) => {
        if (prev) {
          const updatedRevisions = [...prev.revisions];
          updatedRevisions[selectedRevision] = {
            ...updatedRevisions[selectedRevision],
            labeler,
            notes,
          };
          return { ...prev, revisions: updatedRevisions };
        }
        return prev;
      });

      setButtonText("Saved!");
      setTimeout(() => {
        setButtonText("Save");
      }, 2000);
    } catch (error) {
      console.error("Failed to update revision:", error);
    }
  };

  const selectedRevisionData =
    selectedRevision !== -1 ? conversation.revisions[selectedRevision] : null;

  return (
    <div className={styles.navBar}>
      <div className={styles.leftSection}>
        {selectedRevisionData && (
          <>
            <div>
              Labeler:{" "}
              <input
                type="text"
                value={labeler}
                onChange={(e) => setLabeler(e.target.value)}
                placeholder="Enter labeler"
              />
            </div>
            <div>
              Notes:{" "}
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter notes"
              />
              <button
                onClick={handleSaveRevision}
                className={styles.saveButton}
              >
                {buttonText}
              </button>
            </div>
          </>
        )}
      </div>
      <div className={styles.rightSection}>
        <button
          className={`${styles.originalButton} ${
            selectedRevision === -1 ? styles.selected : ""
          }`}
          onClick={() => handleSelectRevision(-1)}
        >
          Original
        </button>
        {conversation.revisions.map((_, index) => (
          <button
            key={index}
            className={`${styles.revisionButton} ${
              selectedRevision === index ? styles.selected : ""
            }`}
            onClick={() => handleSelectRevision(index)}
          >
            Revision {index + 1}
          </button>
        ))}
        <button
          className={styles.newRevisionButton}
          onClick={handleCreateNewRevision}
        >
          New Revision
        </button>
      </div>
    </div>
  );
};

export default AdminChatNavBar;
