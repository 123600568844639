import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AdminCompanyBar.module.css";

interface Company {
  _id: string;
  name: string;
}

const AdminCompanyBar: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminConvo/get-company-list`);
        if (!response.ok) {
          throw new Error("Error fetching companies");
        }
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error("Failed to fetch companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const selectCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
    navigate(`/admin/conversations/${companyId}`);
  };

  const filteredCompanies = companies.filter((company) =>
    company.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.companyBarContainer}>
      <input
        type="text"
        className={styles.companySearchBar}
        placeholder="Search companies"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ul className={styles.companyList}>
        {filteredCompanies.map((company) => (
          <li
            key={company._id}
            className={`${styles.companyItem} ${selectedCompanyId === company._id ? styles.selected : ''}`}
            onClick={() => selectCompany(company._id)}
          >
            {company.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminCompanyBar;
